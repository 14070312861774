import React, { Component } from 'react';
import PropsType from 'prop-types';

import "./ZCInput.css";

class ZCInput extends Component {

  static propTypes = {
    _onTextChange: PropsType.func,
    _onSend: PropsType.func
  }

  static defaultProps = {
    _onTextChange: () => { },
    _onSend: () => { }
  }

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps
      }
    }
  }

  onKeyDown = (e) => {
    let { input, pressEnterToSend } = this.state;
    if (pressEnterToSend && e.keyCode === 13 && e.shiftKey === false) {
      this.onSend({ text: input && input.trim() });
      e.preventDefault();
    }
    return false;
  }

  onSend = (o) => {
    let { input, _onSend, available } = this.state;
    if (!available || input === undefined || input.trim() === "") { return; }
    _onSend(o);
  }

  renderSendBtn = () => {
    return (
      <i className="fas fa-paper-plane" />
    );
  }

  renderInputBox() {
    let { cssPrefix, lang, sendBtn, input, inputPlaceHolder, _onInputChange, onInputRenderAddOns, available } = this.state;
    return (
      <div className={cssPrefix + " zchat-inputbox"}>
        {onInputRenderAddOns && onInputRenderAddOns()}
        <div className={cssPrefix + " zchat-inputfield " + lang}>
          <input
            className={cssPrefix + " zchat-inputfieldinput " + lang}
            ref={this.textareaRef}
            onKeyDown={e => this.onKeyDown(e)}
            onChange={e => _onInputChange(e.target.value)}
            rows="1"
            placeholder={inputPlaceHolder}
            value={input || ""}
            disabled={!available}
          />
        </div>
        <div
          className={cssPrefix + " zchat-send"}
          onClick={() => { this.onSend({ text: input && input.trim() }); }}>
          {sendBtn ? sendBtn() : this.renderSendBtn()}
        </div>
      </div>
    )
  }

  render() {
    let { cssPrefix, menuBtn } = this.state;
    return (
      <div className={cssPrefix + " zchat-input"}>
        {menuBtn && menuBtn()}
        {this.renderInputBox()}
      </div>
    );
  }

}

export default ZCInput;