import React, { Component } from 'react';
import PropsType from 'prop-types';

import ZCTouch from '../ZCTouch';

import "./ZCMsg.css";

class ZCMImage extends Component {

  static propTypes = {
    src: PropsType.string,
  }

  static defaultProps = {
    src: "",
  }

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps
      }
    }
  }

  render() {
    let { cssPrefix, src, onImageClick, title, width, height } = this.props;
    return (
      <ZCTouch onPress={() => onImageClick(src)} style={{ width: width, height: height }}>
        <div className={cssPrefix + " zchat-msg-image"}>
          <img src={src} alt="" title={title} />
        </div>
      </ZCTouch>
    );
  }
}

export default ZCMImage;