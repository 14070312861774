//require("dotenv");
//import "dotenv/config";

export const StartDate = "2020-08";

export const domain = {
  // prd: 'https://chatbot.towngas.com/',
  // uat: 'https://chatbot.tgc-uat.mobinology.com/',
  dev: "https://robot.mobinology.com:8174/",
  //test: "http://localhost:7777/",
  test: "http://localhost:5034/",
  //test: process.env.NODE_DOMAIN,
};

export const chatbot = {
  user: {
    _id: "Chatbot",
    name: "Tinny",
    //avatar: 'Icon/tinny.png',
    avatar: "Image/virtual-img.png",
  },
  interval: 3000,
  version: "2.0.0",
  channel: "Talk2Elain",
};

export const global = {
  lang: [
    { locale: "en", lang: "en", des: "English" },
    { locale: "zh", lang: "tc", des: "中文(繁)" },
  ],
  allPass: "ROOTDEV",
};

export const menu = [
  {
    caption: "DashBoard",
    link: "/Dashboard",
    faIcon: "fas fa-tv fa-lg",
    auth: ["DashBoard"],
  },
  {
    caption: "Conversation",
    link: "/Convlog",
    faIcon: "fas fa-comments fa-lg",
    auth: ["Convlog"],
  },
  {
    caption: "Answer",
    link: "/Answer",
    faIcon: "fas fa-comment-dots fa-lg",
    auth: ["Answer"],
  },
  {
    caption: "Talk to Elain",
    link: "/Talk2Elain",
    faIcon: "fas fa-user-astronaut fa-lg",
    auth: ["Talk2Elain"],
  },
  {
    caption: "Workspace",
    link: "/Workspace",
    faIcon: "fas fa-database fa-lg",
    auth: ["Workspace"],
  },
  {
    caption: "Simulator",
    link: "/Simulator",
    faIcon: "fas fa-vial fa-lg",
    auth: ["Simulator"],
  },
  {
    caption: "Analysis",
    link: "/Analysis",
    faIcon: "fas fa-chart-area fa-lg",
    auth: ["Analysis"],
  },
  {
    caption: "Miscellaneous",
    link: "/Miscellaneous",
    faIcon: "fas fa-cubes fa-lg",
    auth: ["Miscellaneous"],
  },
  {
    caption: "Extension",
    link: "/Extension",
    faIcon: "fas fa-infinity fa-lg",
    auth: ["Extension"],
  },
  {
    caption: "Services",
    link: "/Services",
    faIcon: "fas fa-cubes fa-lg",
    auth: ["Services"],
  },
  {
    caption: "Configuration",
    link: "/Configuration",
    faIcon: "fas fa-cogs fa-lg",
    auth: ["Configuration"],
  },
  {
    caption: "Settings",
    link: "/Settings",
    faIcon: "fas fa-cog fa-lg",
    auth: ["Settings"],
  },
  {
    caption: "Tool Box",
    link: "/ToolBox",
    faIcon: "fas fa-toolbox fa-lg",
    auth: ["ToolBox"],
  },
];
