import React, { Component } from "react";
import PropsType from "prop-types";

import ZCMSysMsg from "./ZCMSysMsg";
import ZCTouch from "../ZCTouch";
import ZCMText from "./ZCMText";
import ZCMImage from "./ZCMImage";
import ZCMButtons from "./ZCMButtons";
import ZCMImgButtons from "./ZCMImgButtons";
import ZCMVideo from "./ZCMVideo";
import ZCMTemplates from "./ZCMTemplates";
import ZCMAnswerReview from "./ZCMAnswerReview";

import ZCMHeader from "./ZCMHeader";
import ZCMFooter from "./ZCMFooter";

import "./ZCMsg.css";

class ZCMsg extends Component {
  static propTypes = {
    _onQuickReply: PropsType.func,
    pos: PropsType.oneOf(["in", "out"]),
    item: PropsType.object,
    last: PropsType.bool,
  };

  static defaultProps = {
    _onQuickReply: () => {},
    pos: "in",
    item: { msg: { text: "" } },
    last: false,
  };

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps,
      };
    }
  }

  renderHeader() {
    let { showHeader, pos } = this.state;
    let { user } = this.state.item;

    if (pos !== "in") return;
    if (!showHeader) return;

    return <ZCMHeader {...this.state} sender={user} />;
  }

  renderMsg() {
    let { disableButtonsAfterSend, last } = this.state;
    let { msg, addOns } = this.state.item;

    let rendered = [];

    if (msg.image) {
      rendered.push(
        <ZCMImage
          {...this.state}
          key={"image"}
          src={msg.image}
          iaddOns={addOns}
        />
      );
    }

    if (msg.video) {
      rendered.push(
        <ZCMVideo
          {...this.state}
          key={"video"}
          video={msg.video}
          iaddOns={addOns}
        />
      );
    }

    if (msg.text) {
      rendered.push(
        <ZCMText
          {...this.state}
          key={"text"}
          text={msg.text}
          iaddOns={addOns}
        />
      );
    }

    if (msg.buttons) {
      rendered.push(
        <ZCMButtons
          {...this.state}
          key={"buttons"}
          buttons={msg.buttons}
          disabled={disableButtonsAfterSend && !last}
          iaddOns={addOns}
        />
      );
    }

    if (msg.imgbuttons) {
      rendered.push(
        <ZCMImgButtons
          {...this.state}
          key={"imgbuttons"}
          buttons={msg.imgbuttons}
          disabled={disableButtonsAfterSend && !last}
          iaddOns={addOns}
        />
      );
    }

    if (msg.templates) {
      rendered.push(
        <ZCMTemplates
          {...this.state}
          key={"template"}
          templates={msg.templates}
          disabled={disableButtonsAfterSend && !last}
          iaddOns={addOns}
        />
      );
    }

    if (msg.answer_review) {
      //console.log("msg", msg);
      rendered.push(
        <ZCMAnswerReview
          {...this.state}
          {...msg.answer_review}
          key={"answer_review"}
          iaddOns={addOns}
        />
      );
    }

    return rendered;
  }

  renderFooter() {
    let { showFooter } = this.state;
    let { createdAt, status, lapseTime } = this.state.item;

    if (!showFooter) return;

    return (
      <ZCMFooter
        {...this.state}
        createdAt={createdAt}
        status={status}
        lapseTime={lapseTime}
      />
    );
  }

  render() {
    let {
      cssPrefix,
      onMsgPress,
      onMsgLongPress,
      pos,
      canClickOnIn,
      canClickOnOut,
    } = this.state;
    let { _id, msg } = this.state.item;

    if (msg.system) {
      return <ZCMSysMsg {...this.state} key={_id} text={msg.system} />;
    }

    let disabled = !(
      (pos === "in" && canClickOnIn) ||
      (pos === "out" && canClickOnOut)
    );

    return (
      <ZCTouch
        {...this.state}
        key={_id}
        onPress={() => onMsgPress(_id)}
        onLongPress={() => onMsgLongPress(_id)}
        disabled={disabled}
      >
        <div className={cssPrefix + " zchat-bubble " + pos}>
          {this.renderHeader()}
          {this.renderMsg()}
          {this.renderFooter()}
        </div>
      </ZCTouch>
    );
  }
}

export default ZCMsg;
