import React, { Component } from 'react';
import PropsType from 'prop-types';
import _ from 'lodash';

import ZCTouch from '../ZCTouch';

import "./ZCMsg.css";

class ZCMImgButtons extends Component {

  static propTypes = {
    buttons: PropsType.array,
    disabled: PropsType.bool
  };

  static defaultProps = {
    buttons: [],
    disabled: true
  };

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps
      };
    }
  }

  renderImgButtons() {
    let { cssPrefix, buttons, _onQuickReply, onPhoneClick, onWebClick, disabled } = this.state;

    let rendered = [];
    _.map(buttons, (o, i) => {
      let func = null;
      switch (o.type) {
        case 'web':
          func = () => onWebClick(o.payload);
          break;
        case 'phone':
          func = () => onPhoneClick(o.payload);
          break;
        default:
          func = () => _onQuickReply(o);
          break;
      }

      rendered.push(
        <div key={i} className={cssPrefix + " zchat-msg-imgbtn "}>
          <ZCTouch cssPrefix={cssPrefix} onPress={func} >
            <div className={cssPrefix + " zchat-msg-imgbtn-img "}>
              <img src={o.image} alt={o.title} draggable={false} />
            </div>
            {o.showText &&
              <div className={cssPrefix + " zchat-msg-imgbtn-text "}>
                {o.title}
              </div>
            }
          </ZCTouch>
        </div>
        //   <div key={i} className={cssPrefix + " zchat-msg-imgbtn " + (disabled? "disabled" : "")}>
        //   <ZCTouch cssPrefix={cssPrefix} onPress={func} disabled={disabled}>
        //     <div className={cssPrefix + " zchat-msg-imgbtn-img " + (disabled? "disabled" : "")}>
        //       <img src={o.image} alt={o.title} draggable={false}/>
        //     </div>
        //     {o.showText &&
        //       <div className={cssPrefix + " zchat-msg-imgbtn-text " + (disabled? "disabled" : "")}>
        //         {o.title}
        //       </div>
        //     }
        //   </ZCTouch>
        // </div>
      );
    });

    return rendered;
  }

  render() {
    let { cssPrefix } = this.state;
    return (
      <div className={cssPrefix + " zchat-msg-imgbtnlist"}>
        {this.renderImgButtons()}
      </div>
    );
  }
}

export default ZCMImgButtons;