import React, { Component } from 'react';

import "./ZChat.css";

class ZCAvatar extends Component {

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this.setState((state, props) => ({ 
      ...props
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat){
    if(prevStat !== nextProps){
      return {
        ...nextProps
      }
    }
  }

  render(){
    let { cssPrefix, iuser, hideImg, onAvatarClick} = this.state;
    return (
      <div className={cssPrefix + " zchat-avatarbox "}>
        {
          !hideImg && 
          <div className={cssPrefix + " zchat-avatar "} onClick={() => onAvatarClick(iuser)}>
            <img src={iuser.avatar} title={iuser.name} alt={iuser.name}/>
          </div>
        }
      </div>
    )
  }
}

export default ZCAvatar;