const Translate = {
  connectFail: {
    EN: "Cannot connect to server.",
    TC: "連接伺服器失敗。",
    SC: "连接伺服器失败。",
  },
  virturalAssistant: {
    EN: "Towngas Virtual Assistant, Tinny",
    TC: "煤氣虛擬助理 Tinny",
    SC: "煤气虚拟助理 Tinny",
  },
  changeLangIcon: {
    EN: "Icon/en.png?v=1",
    TC: "Icon/tc.png?v=1",
    SC: "Icon/sc.png",
  },
  surveyTitle: {
    EN: "We would appreciate it if you would fill in our survey to make your next visit a better one!",
    TC: "請提供您的寶貴意見，讓我們的服務不斷改進。",
    SC: "请提供您的宝贵意见，让我们的服务不断改进。",
  },
  yesAns: {
    EN: "Continue",
    TC: "繼續",
    SC: "继续",
  },
  noAns: {
    EN: "Exit",
    TC: "離開",
    SC: "离开",
  },
  back: {
    EN: "Back",
    TC: "返回",
    SC: "返回",
  },
  others: {
    EN: "Others",
    TC: "其他",
    SC: "其他",
  },
  starTitle: {
    EN: "How satisfied are you with the service provided? (10-highest and 1-lowest)",
    TC: "請為是次服務評分：（ 10分為最高， 1分為最低）",
    SC: "请为是次服务评分：（ 10分为最高， 1分为最低）",
  },
  detailAnswer: {
    EN: "Please specify the catgory(s)of the information:",
    TC: "請問該資料屬於以下哪一個類別？(可選多於一項)",
    SC: "请问该资料属于以下哪一个类别？(可选多于一项)",
  },
  submit: {
    EN: "Submit",
    TC: "提交",
    SC: "提交",
  },
  cancel: {
    EN: "Cancel",
    TC: "取消",
    SC: "取消",
  },
  surveyEnding: {
    EN: "Thank you for using our Virtual Assistant “Tinny”. <br>We look forward to seeing you again! <br><br>Goodbye!",
    TC: "多謝您使用煤氣公司的虛擬助理服務。<br>希望在未來的日子，我們的虛擬助理“Tinny”能繼續為您服務。<br><br>再見！",
    SC: "多谢您使用煤气公司的虚拟助理服务。<br>希望在未来的日子，我们的虚拟助理“Tinny”能继续为您服务。<br><br>再见！",
  },
  reasonTitle: {
    EN: "Do you have any opinion(s)?",
    TC: "請問您有什麼意見？（可選多於一項）",
    SC: "请问您有什么意见？（可选多于一项）",
  },
  highScoreReasonTitle: {
    EN: "Thank you for the high score! May I know the reason please?",
    TC: "感謝您的高評分，請問您對我們服務評分高分的原因是？",
    SC: "感谢您的高评分，请问您对我们服务评分高分的原因是？",
  },
  triggerLiveAgent: {
    EN: "Contact to Live Agent",
    TC: "即時與在線客服聯絡",
    SC: "即时与在线客服联络",
  },
  triggereService: {
    EN: "e-Form",
    TC: "網上查詢表格",
    SC: "网上查询表格",
  },
  alertMsgOthText: {
    EN: "Please fill in the 'Others' comment.",
    TC: "請填寫其他意見。",
    SC: "请填写其他意见。",
  },
  alertMsgReasonCheck: {
    EN: "Please select at least one option.",
    TC: "請選擇至少一個選項。",
    SC: "请选择至少一个选项。",
  },
  liveAgent: {
    Title: {
      EN: "Please let me know your enquiry.<br/>I will transfer the case to Customer Service Officer <br/>to solve the problem!",
      TC: "請問您想查詢以下哪個項目？<br/>稍後我會轉達客戶服務主任，<br/>方便佢幫手解決問題 !",
      SC: "请问您想查询以下哪个项目？<br/>稍后我会转达客户服务主任，<br/>方便他帮手解决问题！",
    },
    SubTitle: {
      EN: "Please select the following button:",
      TC: "請選擇以下按鈕：",
      SC: "请选择以下按钮：",
    },
  },
  busyNetwork: {
    EN: "The network is busy, please input your enquiry again.",
    TC: "網絡繁忙，請重新輸入您的查詢。",
    SC: "网络繁忙，请重新输入您的查询。",
  },
  networkProblem: {
    EN: "Sorry, system temporarily not available. Please check your internet connection and try again later.",
    TC: "對不起，系統暫時未能提供服務，請檢查您的網路連線或稍後再試。",
    SC: "对不起，系统暂时未能提供服务，请检查您的网络连接或稍后再试。",
  },
  resend: {
    EN: "Sorry to keep you waiting, please input your enquiry again.",
    TC: "對不起要您久候，請重新輸入您的查詢。",
    SC: "对不起要您久候，请重新输入您的查询。",
  },
  blockMsg: {
    EN: "You have too many queries in a short time, <br>this page will temporarily stop the service.",
    TC: "你在短時間內有過多的查詢，<br>本頁將會暫時停止提供服務。",
    SC: "你在短时间内有过多的查询，<br>本页将会暂时停止提供服务。",
  },
  meterReport: {
    Title: {
      EN: "Please enter account number and the white numbers on the meter.<br/>Don't enter red numbers.",
      TC: "請輸入客戶號碼及煤氣錶上左至右數字<br/>(請勿報讀紅色數字)：",
      SC: "请输入客户号码及煤气表上左至右数字<br/>(请勿报读红色数字)：",
    },
    AccountNo: {
      EN: "Towngas Account No.",
      TC: "煤氣客戶號碼",
      SC: "煤气客户号码",
    },
    Reading: {
      EN: "Meter Readings",
      TC: "煤氣錶度數",
      SC: "煤气表度数",
    },
    ReportDate: {
      EN: "Report date: ",
      TC: "報讀日期：",
      SC: "报读日期：",
    },
    DateFormat: {
      EN: "D MMM, YYYY",
      TC: "YYYY 年 M 月 D 日",
      SC: "YYYY 年 M 月 D 日",
    },
    ACInorrect: {
      EN: "The Towngas account number entered is incorrect.",
      TC: "您輸入的煤氣客戶號碼不正確",
      SC: "您输入的煤气客户号码不正确",
    },
    ReadingIncorrect: {
      EN: "The meter reading entered is incorrect.",
      TC: "您輸入的煤氣錶度數不正確",
      SC: "您输入的煤气表度数不正确",
    },
  },
};

export default Translate;
