import React, { Component } from "react";
import PropsType from "prop-types";

import htmlParser from "html-react-parser";
import TownGasParser from "./TownGasParser";

import ieDisclaimer from "./Content/IEDisclaimer";

import "./Disclaimer.css";
import "./DisclaimerMS.css";

class Disclaimer extends Component {
  static propTypes = {
    cssPrefix: PropsType.string,
    header: PropsType.object,
    content: PropsType.object,
    lang: PropsType.string,

    onCancel: PropsType.func,
    onConfirm: PropsType.func,
    browser: PropsType.string,
  };

  static defaultProps = {
    cssPrefix: "",
    header: { TC: "", SC: "", EN: "" },
    content: { TC: "", SC: "", EN: "" },
    lang: "TC",

    onCancel: null,
    onConfirm: null,
    browser: "",
  };

  constructor(props) {
    super();
    this.state = {};
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps,
      };
    }
  }

  _onConfirm = () => {
    let { onConfirm } = this.state;
    if (onConfirm) onConfirm();
  };

  _onClose = () => {
    window.close();
  };

  _onCancel = () => {
    let { onCancel } = this.state;
    if (onCancel) onCancel();
  };

  render() {
    let { lang, header, content, cssPrefix, browser } = this.state;

    return (
      <div className={cssPrefix + " t2e-disclaimer"}>
        <div className={cssPrefix + " t2e-disclaimer-inner"}>
          <div className={cssPrefix + " t2e-disclaimer-header"}>
            {TownGasParser.Parse("", htmlParser(header[lang]))}
          </div>
          <div className={cssPrefix + " t2e-disclaimer-content"}>
            {browser === "ie11"
              ? ieDisclaimer[lang]
              : TownGasParser.Parse(
                  cssPrefix + " t2e-disclaimer-content-detail",
                  htmlParser(content[lang])
                )}
          </div>
          <div className={cssPrefix + " t2e-disclaimer-btns"}>
            <div
              className={cssPrefix + " t2e-disclaimer-reject"}
              onClick={() => this._onClose()}
            >
              <div className={cssPrefix + " t2e-disclaimer-reject-text"}>
                {lang === "TC" ? "取消" : "Cancel"}
              </div>
            </div>
            <div
              className={cssPrefix + " t2e-disclaimer-confirm"}
              onClick={() => this._onConfirm()}
            >
              <div className={cssPrefix + " t2e-disclaimer-confirm-text"}>
                {lang === "TC" ? "確定" : "Confirm"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Disclaimer;
