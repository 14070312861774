import React from 'react';

const disclaimer = {
  tc: (
    <table className="ie disclaimertable">
      <tr>
        <td>1.</td>
        <td>為保障大家的私隱，記住唔好在對話內透露任何可直接或間接地令你的身份被辨識的個人資料，例如名字、住址、電話號碼、戶口號碼、登入名稱、 密碼或身份証號碼等資料。</td> 
      </tr>
      <tr>
        <td>2.</td>
        <td>任何經Tinny提供的資料只供參考。</td>
      </tr>
      <tr>
        <td>3.</td>
        <td>任何經Tinny收集閣下的資料或會被本公司、本公司的關聯公司或第三方公司在香港或海外保留、分析或使用。</td>
      </tr>
      <tr>
        <td>4.</td>
        <td>在任何情況下本公司不會就閣下在本煤氣虛擬助理透露任何可令你的身份被辨識的個人資料戓使用本煤氣虛擬助理而引致的損失或損害而負上任何責任。</td>
      </tr>
      <tr>
        <td>5.</td>
        <td>
          請按本
          <div
            className="zchat-msg-htmltag-url"
            onClick={() => window.open("http://www.towngas.com/tc/Info/Privacy",
              "_blank",
              "resizable=1, width=800, height=600, scrollbars=yes")}>
            私隱政策聲明
          </div>
          以進一步暸解本公司之個人資料保護政策。
        </td>
      </tr>
      <tr>
        <td>6.</td>
        <td>如果你已細閱並同意以上的重要提示，請按確認以開始進行對話。</td> 
      </tr>
    </table>
  ),
  en: (
    <table className="ie disclaimertable">
      <tr>
        <td>1.</td>
        <td>Please do NOT disclose in any conversation any personal data which may enable your identity to be directly or indirectly ascertained, such as name, address, telephone number, account number, internet username, password or identity card number.</td> </tr><tr><td>2.</td><td>All information provided through Chatbot is for reference only.</td> 
      </tr>
      <tr>
        <td>3.</td>
        <td>All Information collected from you through Chatbot may be kept, analyzed or used by us, our affiliates or third party companies in Hong Kong or overseas.</td>
      </tr> 
      <tr>
        <td>4.</td>
        <td>In any event our company will not be responsible for any loss or damages arising from your disclosure of identity ascertainable data and use of this Chatbot.</td>
      </tr>
      <tr>
        <td>5.</td>
        <td>
          {"Please click our "}
          <div
            className="zchat-msg-htmltag-url"
            onClick={() => window.open("http://www.towngas.com/en/Info/Privacy",
              "_blank",
              "resizable=1, width=800, height=600, scrollbars=yes")}>
            {" Privacy Policy Statement "}
          </div>
          {" to learn more about our data protection policy."}
        </td>
      </tr>
      <tr>
        <td>6.</td>
        <td>Please confirm you have read and agree with this Important Notice in order to start the conversation.</td> 
      </tr>
    </table>
  )
};

export default disclaimer;
