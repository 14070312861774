import React, { Component } from 'react';
import PropsType from 'prop-types';

import _ from 'lodash';
import { v4 } from 'uuid';

import ZCInput from './ZCInput/ZCInput';
import ZCMsg from './ZCMsg/ZCMsg';
import ZCQReplies from './ZCQReplies/ZCQReplies';

import "./ZChat.css";
import ZCAvatar from './ZCAvatar';

class ZChat extends Component {

  /**
   * Message Type:
   * 
   * //Basic info (info)
   * {
   *    _id: '@string',
   *    createdAt: '@date',
   *    lapseTime?: '@number',
   *    status: 'pending' | 'sent' | 'received' | 'read' | 'error'
   *    user: {
   *      _id: '@string',
   *      name: '@string',
   *      avatar: '@url'
   *    },
   *    msg: '@msg' //See below
   * }
   * 
   * msg variation, can be stacked
   * 
   * //System Message (standalone)
   * {
   *    system?: '@string'
   * }
   * 
   * //Plain Text
   * {
   *    text?: '@string'
   * }
   * 
   * //Image
   * {
   *    image?: '@url'
   * }
   * 
   * //Video
   * {
   *    video?: {
   *      src: '@url',
   *      poster?: '@url'
   *    }
   * }
   * 
   * //QuickReplies
   * {
   *    quickReplies?: [
   *      {
   *        title: '@string',
   *        payload: '@string'
   *      }
   *    ]
   * }
   * 
   * //Buttons
   * {
   *    buttons?: [
   *      {
   *        title: '@string',
   *        payload: '@string',
   *        type: 'web' | 'phone' | 'postback' //default postback
   *      }
   *    ]
   * }
   * 
   * //ImageButtons
   * {
   *    imgbuttons?: [
   *      {
   *        image: '@url',
   *        title: '@string',
   *        payload: '@string',
   *        type: 'web' | 'phone' | 'postback',
   *        showText: '@bool'
   *      }
   *    ]
   * }
   * 
   * //Template
   * {
   *    templates?: [
   *      {
   *        title?: '@string',
   *        subtitle?: '@string',
   *        image?: '@url',
   *        video?: {
   *          src: '@url',
   *          poster?: '@url'
   *        },
   *        text?: '@string',
   *        buttons?: [
   *          title: '@string',
   *          payload: '@string',
   *          type: 'web' | 'phone' | 'postback'
   *        ]
   *      }
   *    ]
   * }
   * 
   */

  static propTypes = {
    cssPrefix: PropsType.string,
    user: PropsType.object,

    onMounted: PropsType.func,
    onSend: PropsType.func,
    onQuickReply: PropsType.func,
    onMsgPress: PropsType.func,
    onMsgLongPress: PropsType.func,
    onInputChange: PropsType.func,
    onAvatarClick: PropsType.func,
    onImageClick: PropsType.func,
    onPhoneClick: PropsType.func,
    onWebClick: PropsType.func,
    onHeaderClick: PropsType.func,

    onInputRenderAddOns: PropsType.func,

    msgIDGen: PropsType.func,
    HTMLSpecialTagParser: PropsType.func,

    pressEnterToSend: PropsType.bool,
    inputPlaceHolder: PropsType.string,

    hideLongAnswer: PropsType.bool,
    longAnswerLength: PropsType.number,
    readMoreCaption: PropsType.string,
    revertReadMore: PropsType.bool,
    readLessCaption: PropsType.string,

    animated: PropsType.bool,

    showHeader: PropsType.bool,
    showFooter: PropsType.bool,
    showStatus: PropsType.bool,
    showDateTime: PropsType.bool,
    showLapseTime: PropsType.bool,

    showInAvatar: PropsType.bool,
    hideSameAvatar: PropsType.bool,
    avatarAtTop: PropsType.bool,

    appendTextImed: PropsType.bool,
    quickReplyBar: PropsType.bool,
    showQuickRepliesAsButtons: PropsType.bool,
    disableButtonAfterSend: PropsType.bool,

    canClickOnIn: PropsType.bool,
    canClickOnOut: PropsType.bool,
    HTMLEnabled: PropsType.bool,

    available: PropsType.bool,

    addOns: PropsType.object,
    lang: PropsType.string
  }

  static defaultProps = {
    cssPrefix: "",
    user: {
      _id: 'User',
      name: 'User',
      avatar: undefined
    },

    onMounted: null, //(callbacks) => {},
    onSend: null, //(input, msgID) => {},
    onQuickReply: null, //(quickReply, msgID) => {},
    onMsgPress: null, //(messageId) => {},
    onMsgLongPress: null, //(messageId) => {},
    onInputChange: null, //(text) => {},
    onAvatarClick: (user) => { },
    onImageClick: (src) => { },
    onPhoneClick: (payload) => { },
    onWebClick: (url) => { window.open(url); },
    onHeaderClick: (user) => { },

    onInputRenderAddOns: null,

    msgIDGen: () => v4(),
    HTMLSpecialTagParser: null, //(cssPrefix, token) => {return (<div/>)},

    hideLongAnswer: true,
    longAnswerLength: 300,
    readMoreCaption: "Read more...",
    revertReadMore: true,
    readLessCaption: "Read less",

    pressEnterToSend: true,
    inputPlaceHolder: "Please input something here...",

    animated: false,

    showHeader: false,
    showFooter: true,
    showStatus: false,
    showDateTime: true,
    showLapseTime: true,

    showInAvatar: true,
    showOutAvatar: false,
    hideSameAvatar: true,
    avatarAtTop: true,

    appendTextAfterSend: true,
    quickReplyBar: true,
    showQuickRepliesAsButtons: true,
    disableButtonsAfterSend: true,

    canClickOnIn: false,
    canClickOnOut: false,
    HTMLEnabled: true,

    available: true,

    addOns: {},
    lang: ""
  }

  constructor() {
    super();
    this.state = {
      typingDisabled: false,
      inQR: false,
      messages: [],
      quickReplies: []
    }
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props
    }));

    if (this.props.onMounted) {
      this.props.onMounted({
        Append: this._Append,
        GetMsg: this._GetMsg,
        SetQuickReplies: this._setQuickReplies,
        SetStatus: this._setStatus
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps,
        cssPrefix: nextProps.cssPrefix + " " + nextProps.browser + (nextProps.animated ? " animated " : "")
      }
    }
  }

  _setStatus = (msgID, status) => {
    let { messages } = this.state;
    _.map(messages, (o, i) => {
      if (msgID === o._id) {
        o.status = status;
      }
    });
  }

  _GetMsg = () => {
    return this.state.messages;
  }

  _Append = (msgs) => {

    if (!Array.isArray(msgs)) {
      msgs = [msgs]
    }

    if (msgs.length === 0) return;

    let lastmsg = msgs[msgs.length - 1];
    let quickReplies = lastmsg.msg.quickReplies || [];

    this.setState((state, props) => ({
      messages: state.messages.concat(msgs),
      quickReplies: quickReplies,
      inQR: quickReplies.length > 0
    }), () => {
      this._scrollToBottom();
    });
  }

  _setQuickReplies = (quickReplies) => {
    this.setState({
      quickReplies: quickReplies
    });
  }

  _setTypingDisabled = (b) => {
    this.setState({
      typingDisabled: b
    })
  }

  _resetInput = () => {
    this.setState({
      input: undefined
    });
  }

  _scrollToBottom = (animated = true) => {
    if (this.messageEnd === null) {
      return;
    }
    this.messageEnd.scrollIntoView({ behavior: 'smooth' });
  }

  _onSend = (input) => {
    let { msgIDGen, user, onSend, appendTextAfterSend } = this.state;

    let msg = {
      user: user,
      createdAt: new Date(),
      status: 'sent',
      _id: msgIDGen(),
      msg: input
    }

    this._setTypingDisabled(true);
    this._resetInput();

    if (appendTextAfterSend) {
      this._Append(msg);
    }

    if (onSend) {
      onSend(input, msg._id);
    }

    this._scrollToBottom();

    setTimeout(() => {
      this._setTypingDisabled(false);
    }, 100);

  }

  _onQuickReply = (quickReply) => {
    let { msgIDGen, user, onQuickReply, appendTextAfterSend } = this.state;

    let msg = {
      user: user,
      createdAt: new Date(),
      status: 'sent',
      _id: msgIDGen(),
      msg: {
        text: quickReply.title
      }
    };

    this._setTypingDisabled(true);
    this._resetInput();

    if (appendTextAfterSend) {
      this._Append(msg);
    }

    if (onQuickReply) {
      onQuickReply(quickReply, msg._id);
    }

    this._scrollToBottom();

    setTimeout(() => {
      this._setTypingDisabled(false);
    }, 100);
  }

  _onInputChange = (input) => {
    let { typingDisabled, onInputChange } = this.state;
    if (typingDisabled) return;

    if (onInputChange)
      onInputChange(input);

    this.setState({
      input
    });
  }

  renderMsgs() {
    let { messages, cssPrefix, user, showInAvatar, showOutAvatar, avatarAtTop, hideSameAvatar } = this.state;
    return _.map(messages, (o, i) => {
      let last = i === messages.length - 1;
      let pos = o.user._id === user._id ? 'out' : 'in';
      let hideImg = false;
      if (hideSameAvatar) {
        if (avatarAtTop) {
          let prevMsg = messages[i - 1];
          hideImg = prevMsg && prevMsg.user._id === o.user._id;
        } else {
          let nextMsg = messages[i + 1];
          hideImg = nextMsg && nextMsg.user._id === o.user._id;
        }
      }

      return (
        <div className={cssPrefix + " zchat-msgrow " + (avatarAtTop ? "attop" : "")} key={i}>
          {
            showInAvatar && pos === "in" && !o.msg.system &&
            <ZCAvatar
              {...this.state}
              iuser={o.user}
              hideImg={hideImg}
            />
          }
          <ZCMsg
            {...this.state}
            _onQuickReply={this._onQuickReply}
            _onSend={this._onSend}
            item={o}
            last={last}
            pos={pos}
          />
          {
            showOutAvatar && pos === "out" && !o.msg.system &&
            <ZCAvatar
              {...this.state}
              iuser={o.user}
              hideImg={hideImg}
            />
          }
        </div>
      );
    });
  }

  renderMsgList() {
    let { cssPrefix } = this.state;
    return (
      <div className={cssPrefix + " zchat-msglist"}>
        {this.renderMsgs()}
        <div ref={e => this.messageEnd = e} />
      </div>
    );
  }

  renderQuickReplyBar() {
    return (
      <ZCQReplies
        {...this.state}
        _onQuickReply={this._onQuickReply}
        disabled={false}
      />
    );
  }

  renderInputBar() {
    return (
      <ZCInput
        {...this.state}
        _onInputChange={this._onInputChange}
        _onSend={this._onSend}
      />
    );
  }

  render() {
    let { cssPrefix, quickReplyBar, inQR } = this.state;
    return (
      <div className={cssPrefix + " zchat"}>
        {this.renderMsgList()}
        {quickReplyBar && inQR && this.renderQuickReplyBar()}
        {this.renderInputBar()}
      </div>
    );
  }

}

export default ZChat;