/*REACT*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*BASE*/
import * as _base from "../../../_base";
import ZChat from "../../_Utilities/ZChat/ZChat";
import Disclaimer from "./_Parts/Disclaimer";
import TownGasParser from "./_Parts/TownGasParser";
import Survey from "./_Parts/Survey";
import ZDetectBrowser from "../../_Utilities/ZDetectBrowser/ZDetectBrowser";

import { v1 } from "uuid";
import axios from "axios";
import _ from "lodash";
import Translate from "./_Parts/Translate";

/*CSS*/
import "./Talk2Elain.css";
import "./Talk2ElainMS.css";
import { DOMAIN } from "../../../_base/actions";
import { TradOrSimp } from "./SC_Trans";

let disabledSC = process.env.REACT_APP_DISABLE_SC === "true" ? true : false;

class Talk2Elain extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super();
    const urlLang = (props.match.params.lang || "TC").toUpperCase();
    this.state = {
      lang: ["EN", "TC"].includes(urlLang) ? urlLang : "TC",
      intents: [],
      records: [],
      context: {
        DEBUG: false,
      },
      timeout: 6000,
      reminderEnabled: false,
      showReminder: true,
      showDisclaimer: true,
      showMenu: false,
      showSurvey: false,
      showReview: false,
      welcome: false,
      inputPlaceholder: "",
      available: true,
      maintenance: false,
      maintenanceHtml: null,
      retain: false,
      followingIntentEnabled: true,
      followingIntentNumber: 0,
    };
  }

  onMountBrowser = (callbacks) => {
    this.MountBrowser = callbacks;
  };

  onMountSurvey = (callbacks) => {
    this.MountSurvey = callbacks;
  };

  onMountChat = (callbacks) => {
    this.MountChat = callbacks;
  };

  Alert = (msg) => {
    this.setState(
      {
        showSurvey: true,
      },
      () => {
        this.MountSurvey.Alert(msg);
      }
    );
  };

  componentDidMount() {
    document.body.style.setProperty("--app-height", `${window.innerHeight}px`);

    this.setState((state, props) => ({
      ...props,
      authority:
        (props.user &&
          props.user.authority &&
          props.user.authority.Talk2Elain) ||
        [],
    }));

    this.setAllStates(
      this.setChatbot(() => {
        console.log("[>] Connect to " + this.state.url);
        this.getConfig();
      })
    );
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps,
      };
    }
  }

  getBrowser = () => {
    return this.MountBrowser && this.MountBrowser.GetBrowser();
  };

  BlockEntry = (unBlockTime) => {
    this.setState(
      {
        showSurvey: true,
        available: false,
      },
      () => {
        console.log(this.state.available);
        this.MountSurvey.Block(unBlockTime);

        if (this.UnblockTimeout) clearTimeout(this.UnblockTimeout);
        this.UnblockTimeout = setTimeout(() => {
          this.setState({
            available: true,
          });
        }, unBlockTime);
      }
    );
  };

  RestartChat = () => {
    this.setState({
      convID: v1(),
      records: [],
      messages: [],
      showMenu: false,
      showReminder: true,
      showDisclaimer: true,
    });
  };

  setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  setChatbot = (callback) => {
    console.log("[-] START CHATBOT");
    let { interval } = _base.config.chatbot;

    this.setState(
      {
        convID: v1(),
        livechat: false,
        //url: DOMAIN + "conv",
        url: DOMAIN + "Talk2VA",
        timeout: interval,
        sending: false,
      },
      callback
    );
  };

  getConfig = async () => {
    let { lang } = this.state;
    let url = DOMAIN + "GetConfig";
    let input = {
      session_id: v1(),
    };
    try {
      let res = await axios.get(url);
      console.log("[-] Remote Config: ", res.data.content);

      this.setState(
        {
          remoteConfig: res.data.content,
          maintenanceHtml: res.data.content.MaintenancePage,
          maintenance: res.data.content.Maintenance,
          followingIntentEnabled: res.data.content.MWSettings.FollowingIntent,
          followingIntentNumber:
            res.data.content.MWSettings.FollowingIntentNumber,
        },
        () => {
          this.SetPlaceHolder(0);
          this.welcomeMessage();
        }
      );
    } catch (e) {
      this.Alert(Translate.connectFail[lang]);
      this.onLeaveRedirect();
    }
  };

  welcomeMessage = () => {
    if (
      this.state.remoteConfig.MWSettings.WelcomeMessage !== undefined &&
      this.state.remoteConfig.MWSettings.WelcomeMessage == true
    ) {
      console.log("[-] welcomeMessage");
      const now = new Date();

      let Welcome_time;
      if (now.getHours() > 3 && now.getHours() < 12) {
        Welcome_time = "CMD_WELCOME_MORNING";
      } else if (now.getHours() >= 12 && now.getHours() < 18) {
        Welcome_time = "CMD_WELCOME_AFTERNOON";
      } else {
        Welcome_time = "CMD_WELCOME_EVENING";
      }
      console.log("Welcome_time", Welcome_time);
      let input = {
        text: Welcome_time,
        field: "Welcome",
      };
      this.welcome = true;
      this.sendToServer(input, null, true);
    } else {
      console.log("WelcomeMessage Closed");
    }
  };

  SetPlaceHolder = (mode) => {
    let { remoteConfig, lang } = this.state;
    if (!remoteConfig) return;

    let placeholder = { TC: "", SC: "", EN: "" };
    let { InputFieldSetting } = remoteConfig;
    switch (mode) {
      case 0:
        placeholder = InputFieldSetting.Message.WelcomeInstruction;
        break;
      case 1:
        placeholder = InputFieldSetting.Message.ButtonInstruction;
        break;
      default:
        placeholder = InputFieldSetting.Message.TextInstruction;
        break;
    }

    this.setState({
      inputPlaceholder: placeholder[lang],
    });
  };

  //#region Idle

  DocTitleBlink = (start) => {
    let title = "Towngas Virtual Assistant";
    let newmsg = "New Message!!";
    if (start) {
      this.DocTitleBlinkHandler = setInterval(() => {
        document.title = document.title === title ? newmsg : title;
      }, 1000);
    } else {
      if (this.DocTitleBlinkHandler) {
        clearInterval(this.DocTitleBlinkHandler);
      }
      document.title = title;
    }
  };

  setIdleTimeout = () => {
    console.log("setIdleTimeout");
    let { remoteConfig } = this.state;
    if (!remoteConfig) return;

    this.DocTitleBlink(false);
    let first = remoteConfig.Timeout.TimeSetting["1st"];
    let second = remoteConfig.Timeout.TimeSetting["2nd"];

    if (this.idleAskHandler) {
      console.log("Clear Timeout");
      clearTimeout(this.idleAskHandler);
    }
    if (this.idleTimoutRedirectHandler) {
      console.log("Clear Timeout");
      clearTimeout(this.idleTimoutRedirectHandler);
    }
    this.idleAskHandler = setTimeout(this.idleAsk, first * 60000);
    this.idleTimoutRedirectHandler = setTimeout(
      this.onLeaveRedirect,
      (first + second) * 60000
    );
  };

  idleAsk = () => {
    let { remoteConfig, lang } = this.state;
    if (!remoteConfig) return;

    console.log("idleAsk");
    this.DocTitleBlink(true);
    let { Question } = remoteConfig.Timeout;

    let yesNoMapping = {};

    //[LY]SC related
    if (disabledSC) {
      yesNoMapping = {
        TC: "是",
        EN: "Yes",
      };
    } else {
      yesNoMapping = {
        TC: "是",
        SC: "是",
        EN: "Yes",
      };
    }

    //[LY]SC related
    const yesText = yesNoMapping[lang] || "Yes";
    const noText = yesNoMapping[lang] === "是" ? "否" : "No";

    let msg = {
      _id: v1(),
      createdAt: new Date(),
      user: _base.config.chatbot.user,
      msg: {
        text: Question[lang],
        buttons: [
          {
            title: yesText,
            payload: "CMD_BTN IDLE_YES",
          },
          {
            title: noText,
            payload: "CMD_BTN IDLE_NO",
          },
        ],
      },
    };

    this.MountChat.Append(msg);
  };

  idleYes = () => {
    let { remoteConfig, lang } = this.state;
    if (!remoteConfig) return;

    console.log("idleAsk");
    let { Continue } = remoteConfig.Timeout;
    let msg = {
      _id: v1(),
      createdAt: new Date(),
      user: _base.config.chatbot.user,
      msg: {
        text: Continue[lang],
      },
    };

    this.MountChat.Append(msg);
  };

  idleNo = () => {
    this.Leave();
  };

  //#endregion Idle

  TriggerLiveAgent = () => {
    let { remoteConfig } = this.state;
    if (!remoteConfig) return;
    this.setState(
      {
        showSurvey: true,
      },
      () => {
        this.MountSurvey.TriggerLiveAgent();
      }
    );
  };

  TriggerMeterReport = () => {
    let { remoteConfig } = this.state;
    if (!remoteConfig) return;
    this.setState(
      {
        showSurvey: true,
      },
      () => {
        this.MountSurvey.TriggerMeterReport();
      }
    );
  };

  appendReturnText = (messages, answerID, answer_review) => {
    if (!Array.isArray(messages)) {
      messages = [messages];
    }
    if (messages.length <= 0) return;

    let { lang, convID } = this.state;
    let addOns = {
      answerID: answerID,
      convID: convID,
      lang: lang,
      triggereServiceLink: this.TriggereServiceLink,
      triggerLiveAgent: this.TriggerLiveAgent,
      triggerMeterReport: this.TriggerMeterReport,
    };

    let msgs = [];
    _.map(messages, (o, i) => {
      //[LY]Here should be catching the live agent answer ID
      if (o === "Trigger_Live_Agent") {
        this.TriggerLiveAgent();
      } else if (o === "Trigger_Meter_Report") {
        this.TriggerMeterReport();
      } //else if (o.trim() !== "") {
      else {
        //[LY]not sure about trim(). delete first
        msgs.push({
          _id: v1(),
          createdAt: new Date(),
          user: _base.config.chatbot.user,
          msg: {
            text: o.text,
          },
          addOns: addOns,
        });
      }
    });
    if (msgs.length > 0 && answer_review) {
      msgs.push({
        _id: v1(),
        createdAt: new Date(),
        user: _base.config.chatbot.user,
        msg: { answer_review },
        addOns: addOns,
      });
    }
    if (this.state.maintenance == false) {
      this.MountChat.Append(msgs);
    }
  };

  appendReturnTemplate = (messages, answerID, answer_review, welcome) => {
    if (!Array.isArray(messages)) {
      messages = [messages];
    }
    if (messages.length <= 0) return;

    let { lang, convID } = this.state;
    let addOns = {
      answerID: answerID,
      convID: convID,
      lang: lang,
      triggereServiceLink: this.TriggereServiceLink,
      triggerLiveAgent: this.TriggerLiveAgent,
      triggerMeterReport: this.TriggerMeterReport,
    };

    let msgs = [];

    console.log("messages", messages);

    _.map(messages, (o, i) => {
      let _msg = {
        _id: v1(),
        createdAt: new Date(),
        user: _base.config.chatbot.user,
        msg: {
          text: o.text,
          //buttons: o.buttons,//[LY]Found that quickReplies is button but not quickReplies
        },
        addOns: addOns,
      };

      let _menu = {
        _id: v1(),
        createdAt: new Date(),
        user: _base.config.chatbot.user,
        msg: {
          //if this.lang is TC, then use TC text, if this.lang=SC, then use SC text, if this.lang=EN, then use EN text
          text:
            this.state.lang === "TC"
              ? "我可以為你解答有關以下既問題:(請選擇以下按鈕)"
              : this.state.lang === "SC"
              ? "我可以为你解答有关以下的问题:(请选择以下按钮)"
              : "I can help you with the followings:(Please select the following button)",
          imgbuttons:
            this.state.lang === "TC"
              ? [
                  {
                    type: "postback",
                    image: "Buttons/001_c.png",
                    title: "賬戶管理(開立／終止／轉名／搬屋及租賃)",
                    payload: "CMD_BTN FAQ_Account",
                  },
                  {
                    type: "postback",
                    image: "Buttons/002_c.png",
                    title: "賬單及報錶",
                    payload: "CMD_BTN FAQ_Billing",
                  },
                  {
                    type: "postback",
                    image: "Buttons/003_c.png",
                    title: "安裝工程",
                    payload: "CMD_BTN FAQ_Installation",
                  },
                  {
                    type: "postback",
                    image: "Buttons/004_c.png",
                    title: "維修保養",
                    payload: "CMD_BTN FAQ_Maintenance",
                  },
                  {
                    type: "postback",
                    image: "Buttons/005_c.png",
                    title: "其它",
                    payload: "CMD_BTN FAQ_Other",
                  },
                ]
              : this.state.lang === "SC"
              ? [
                  {
                    type: "postback",
                    image: "Buttons/001_s.png",
                    title: "账户管理(开立／终止／转名／搬屋及租赁)",
                    payload: "CMD_BTN FAQ_Account",
                  },
                  {
                    type: "postback",
                    image: "Buttons/002_s.png",
                    title: "账单及报錶",
                    payload: "CMD_BTN FAQ_Billing",
                  },
                  {
                    type: "postback",
                    image: "Buttons/003_s.png",
                    title: "安装工程",
                    payload: "CMD_BTN FAQ_Installation",
                  },
                  {
                    type: "postback",
                    image: "Buttons/004_s.png",
                    title: "维修保养",
                    payload: "CMD_BTN FAQ_Maintenance",
                  },
                  {
                    type: "postback",
                    image: "Buttons/005_s.png",
                    title: "其它",
                    payload: "CMD_BTN FAQ_Other",
                  },
                ]
              : [
                  {
                    type: "postback",
                    image: "Buttons/001_e.png",
                    title:
                      "Account Management (Open/Terminate/Change Name/House Moving & Leasing)",
                    payload: "CMD_BTN FAQ_Account",
                  },
                  {
                    type: "postback",
                    image: "Buttons/002_e.png",
                    title: "Bill & Report meter reading",
                    payload: "CMD_BTN FAQ_Billing",
                  },
                  {
                    type: "postback",
                    image: "Buttons/003_e.png",
                    title: "Installation works",
                    payload: "CMD_BTN FAQ_Installation",
                  },
                  {
                    type: "postback",
                    image: "Buttons/004_e.png",
                    title: "Maintenance",
                    payload: "CMD_BTN FAQ_Maintenance",
                  },
                  {
                    type: "postback",
                    image: "Buttons/005_e.png",
                    title: "Others",
                    payload: "CMD_BTN FAQ_Other",
                  },
                ],
        },
      };

      if (o.followingIntent && this.state.followingIntentEnabled == true) {
        if (o.followingIntent.length > this.state.followingIntentNumber) {
          _msg.msg.quickReplies = o.followingIntent.slice(
            0,
            this.state.followingIntentNumber
          );
        } else {
          _msg.msg.quickReplies = o.followingIntent;
        }
      }

      //foreach _msg.msg.quickReplies.field="Suggested Intent"
      if (_msg.msg.quickReplies) {
        _msg.msg.quickReplies.forEach((o) => {
          o.field = "Suggested Intent";
        });
      }

      if (o.quickReplies) {
        _msg.msg.buttons = o.quickReplies;
        if (_msg.msg.buttons.length === 1) {
          this.SetPlaceHolder(2);
        } else {
          this.SetPlaceHolder(1);
        }
        //The last answer shows review
        //Not show answer review for now
        // if (_msg.msg.buttons.length === 1) {
        //   this.showReview = true;
        // }
      }

      //No review for 0 buttons answer
      // else {
      //   this.showReview = true;
      // }
      console.log("msgs before push", msgs);
      msgs.push(_msg);
      console.log("_msg", _msg);
      console.log("msgs after push", msgs);

      if (o.text == "Watson service not initialized") {
        this.setState({
          maintenance: true,
        });
      }

      if (welcome) {
        msgs.push(_menu);
      }
    });

    if (msgs.length > 0 && answer_review && this.showReview && !this.welcome) {
      msgs.push({
        _id: v1(),
        createdAt: new Date(),
        user: _base.config.chatbot.user,
        msg: { answer_review },
        addOns: addOns,
      });
    }

    console.log("msgs after 2nd push", msgs);

    if (this.state.maintenance == false) {
      this.MountChat.Append(msgs);
      console.log("this.MountCHat", this.MountChat);
    }

    if (this.showReview) this.showReview = false;
    if (this.welcome) this.welcome = false;
  };

  onSend = (input, _id) => {
    let { lang } = this.state;
    let addOns = {
      lang: lang,
      triggereServiceLink: this.TriggereServiceLink,
      triggerLiveAgent: this.TriggerLiveAgent,
      triggerMeterReport: this.TriggerMeterReport,
    };

    if (/\b\d{7}\b|\b\d{10}\b/.test(input.text)) {
      const message =
        this.state.remoteConfig.SpecialAnswer.digitQuery[this.state.lang] || "";
      let msg = {
        _id: v1(),
        createdAt: new Date(),
        user: _base.config.chatbot.user,
        msg: {
          text: message,
        },
        addOns: addOns,
      };

      this.MountChat.Append(msg);
      return;
    }

    this.setIdleTimeout();
    //handle image intent
    if (input.image_url_intent_input !== undefined) {
      input.text = input.image_url_intent_input;
      input.field = "Image URL - Direct Answer";
    }
    //detect language
    this.setState(
      (state, prop) => ({
        lang: this.Language(input.text, lang),
      }),
      () => {
        this.SetPlaceHolder();
        this.sendToServer(input, _id);
      }
    );
  };

  //[LY][done]handling idle choice first??(IDLE QRB is not sending to MW)
  onQuickReply = (quickReply, _id) => {
    this.setIdleTimeout();
    let input = {
      text: quickReply.payload,
      field: quickReply.field || "Button - dialog flow",
    };

    if (input.text === "CMD_BTN IDLE_YES") {
      this.idleYes();
    } else if (input.text === "CMD_BTN IDLE_NO") {
      this.idleNo();
    } else {
      //detect
      let { lang } = this.state;
      this.setState(
        (state, prop) => ({
          lang: this.Language(input.text, lang),
        }),
        () => {
          this.SetPlaceHolder(1);
          this.sendToServer(input, _id);
        }
      );
    }
  };

  onAnswerReview = async (answer_id, like) => {
    let { convID, CSRF, intent } = this.state;

    await axios.post(
      //[LY][done]New api for answer review
      DOMAIN + "AnswerReview",
      {
        context: { convID, answer_id, like, intent },
        csrf: CSRF,
      },
      {
        "Content-Type": "application/json",
      }
    );

    console.log("Answer Review Content:", { convID, answer_id, like, intent });
  };

  //copy from old mw
  TriggereServiceLink = async (
    context,
    lang,
    ansId,
    convID,
    NeedToRedirect
  ) => {
    if (NeedToRedirect == true) {
      const RedirectURL = this.state.remoteConfig.RedirectURL;
      const matchedItem = RedirectURL.find((item) => item.Category === context);
      if (matchedItem && matchedItem.URL && matchedItem.URL[lang]) {
        let browser = this.getBrowser();
        if (browser === "safari") {
          setTimeout(() => {
            let myWindow = window.open(
              "",
              "_blank",
              "resizable=1,width=800,height=600,scrollbars=yes"
            );
            myWindow.location = matchedItem.URL[lang];
          });
        } else {
          let myWindow = window.open(
            "",
            "MyWindow",
            "resizable=1,width=800,height=600,scrollbars=yes"
          );

          myWindow.location = matchedItem.URL[lang];
        }
      } else {
        console.log(
          "No matching URL found for the given context and language."
        );
      }
    }

    //Context = Category
    let payload = {
      context: context,
      lang: lang,
      ansId: ansId,
      convID: convID,
    };

    let url = DOMAIN + "EService";
    let res = await axios.post(url, payload);
  };

  //the original TriggereServiceLink
  GeteServiceLink = async (context, lang, ansId, convID) => {
    let payload = {
      context: context,
      lang: lang,
      ansId: ansId,
      convID: convID,
    };

    let url = DOMAIN + "EService";
    let res = await axios.get(url, payload);

    console.log(res);

    let urlGen = res.data;
    if (urlGen) {
      let browser = this.getBrowser();
      let myWindow = window.open(
        "",
        browser === "safari" ? "_self" : "MyWindow",
        "resizable=1,width=800,height=600,scrollbars=yes"
      );

      myWindow.location = urlGen;

      let payload = {
        context: context,
        lang: lang,
        ansId: ansId,
        convID: convID,
      };

      let url = DOMAIN + "EService";
      let res = await axios.post(url, payload);
      console.log(res);
    }
  };

  MeterReadingReturn = (msg, answerID) => {
    this.appendReturnText(msg, answerID);
  };

  RedirectToLiveAgent = async (cat) => {
    console.log("LIVEAGENT API CALL");

    let { convID, remoteConfig, lang } = this.state;

    let payload = {
      SessionId: convID,
      Category: cat,
    };

    let now = new Date();
    let today = now.toISOString().slice(0, 10);
    let nonServiceDayList = remoteConfig.LiveAgent.NonServiceDayList.split(";");
    let openingHourFrom = remoteConfig.LiveAgent.OpeningHour.From;
    let openingHourTo = remoteConfig.LiveAgent.OpeningHour.To;
    let Service24HRAvailable = remoteConfig.LiveAgent.Service24HRAvailable;

    // check service time
    let currentHour =
      now.getHours() +
      ":" +
      (now.getMinutes() < 10 ? "0" : "") +
      now.getMinutes();
    if (
      !nonServiceDayList.includes(today) &&
      (Service24HRAvailable ||
        (currentHour >= openingHourFrom && currentHour <= openingHourTo))
    ) {
      let url = DOMAIN + "LiveAgentRecord";
      let res = await axios.post(url, payload);

      if (res.status !== "503") {
        let url = remoteConfig.LiveAgent.URL[lang] + "&sid=" + convID;
        console.log(url);
        window.open(url, "_self");
      } else {
        this.Alert(Translate.connectFail[lang]);
        // this.onLeaveRedirect();
      }
    } else {
      this.Alert(remoteConfig.LiveAgent.Message.NoService[lang]);
    }
  };

  handleReturn = (output, welcome, answerID, answer_review) => {
    console.log("handleReturn output:", output);

    if (output) {
      if (welcome) {
        this.SetPlaceHolder(0);
      }
      if (
        output.text &&
        output.text ===
          "It seems that there is an error. Please contact us if the problem persists."
      ) {
        output.text =
          this.state.remoteConfig.ErrorMessage.busyNetwork[this.state.lang] ||
          Translate.busyNetwork[this.state.lang.toLowerCase()];
      }

      this.appendReturnTemplate(output, answerID, answer_review, welcome);
    }
    return;
  };

  sendToServer = async (input, _id, welcome = false) => {
    let { url, lang, context, convID, CSRF } = this.state;

    if (input.text == "Live Agent" && this.state.retain == true) {
      this.setState({
        retain: false,
      });
      this.TriggerLiveAgent();
      return;
    }

    //should add a inputFeild in input, and get it here
    let payload2VA = {
      session_id: convID,
      device_id: localStorage.getItem("deviceId"),
      user_id: "tgc",
      channel: "Talk2VA",
      input: {
        type: input.field || "text",
        content: input.text,
      },
      Location: "",
      lang: this.state.lang,
    };

    if (input.field !== undefined) {
      payload2VA.Location = input.field;
    } else {
      payload2VA.Location = "userInput";
    }

    if (this.state.csrf !== undefined) {
      payload2VA.csrf = this.state.csrf;
    }

    let headers = {
      "Content-Type": "application/json",
    };

    console.log("payload2VA", payload2VA); //need to add csrf later

    try {
      let res = await axios.post(url, payload2VA, {
        headers: headers,
        withCredentials: true,
      });

      //if only csrf returned
      if (
        res.data &&
        Object.keys(res.data).length === 1 &&
        "csrf" in res.data
      ) {
        let csrf = res.data.csrf;
        this.setState({ csrf: csrf });
        this.welcomeMessage();
      } else if (res.data) {
        console.log("res.data", res.data);

        let answerID = res.data.ansRes.__ans;
        let output = res.data.message[0].msg;
        //handle "don't understand + redirect to eService"
        if (res.data.message.length > 1) {
          output = res.data.message[1].msg;
        }

        let intent = res.data.intent;
        if (res.data.intent == null) {
          if (input.text.startsWith("CMD_BTN ")) {
            intent = input.text.slice("CMD_BTN ".length);
          }
        }

        if (
          res.data.intent == "FAQ_Other_LiveAgent" ||
          res.data.ansRes.__ans == "master:FAQ_Other_LiveAgent"
        ) {
          if (this.state.remoteConfig.LiveAgent.RetainCustomer == true) {
            if (this.state.retain == false) {
              this.setState({
                retain: true,
              });
              console.log("Live Agent retain", this.state.retain);
            } else {
              this.setState({
                retain: false,
              });
              console.log("Live Agent retain", this.state.retain);
              this.TriggerLiveAgent();
            }
          } else {
            this.TriggerLiveAgent();
          }
        }

        let csrf = res.data.csrf;
        let answer_review = this.state.remoteConfig.AnswerReview;

        this.setState(
          {
            context: context,
            showReminder: welcome,
            intent: intent,
            CSRF: csrf,
          },
          () => {
            if (!convID) {
              this.setState(
                {
                  convID: context.conversation_id,
                },
                () => {
                  this.handleReturn(output, welcome, answerID, answer_review);
                }
              );
            } else {
              this.handleReturn(output, welcome, answerID, answer_review);
            }
          }
        );
      } else {
        _base.func.handleError(this.props, res.data, (e) => {
          this.Alert(Translate.connectFail[lang]);
        });
      }
    } catch (e) {
      if (e.response) {
        let status = e.response.status;
        const busyCode = [404, 427, 428, 429];

        if (busyCode.indexOf(status) !== -1) {
          this.appendReturnText(
            this.state.remoteConfig.ErrorMessage.busyNetwork[lang] ||
              Translate.busyNetwork[lang.toLocaleLowerCase()],
            null
          );
        } else if (status === 0) {
          this.appendReturnText(
            this.state.remoteConfig.ErrorMessage.resend[lang] ||
              Translate.resend[lang.toLowerCase()],
            null
          );
        } else if (status === 502) {
          this.appendReturnText(
            this.state.remoteConfig.ErrorMessage.networkProblem[lang] ||
              Translate.networkProblem[lang.toLowerCase()],
            null
          );
        } else if (status === 503) {
          let { unBlockTime } = e.response.data;
          this.BlockEntry(unBlockTime);
        }
      } else {
        _base.func.handleError(this.props, e, (e) => {
          this.appendReturnText(Translate.connectFail[lang], null);
        });
      }
    }
  };

  Language = (input, _lang) => {
    const isInt = /^\d+$/;
    // Check if input starts with "CMD" and return current language if true
    if (
      input.startsWith("CMD") ||
      (input == "Live Agent" && this.state.retain == true)
    ) {
      return _lang;
    }
    if (input && input.length > 0 && !isInt.test(input)) {
      if (/\S?[\u4E00-\u9FFF\uF900-\uFAFF]\S?/i.test(input)) {
        if (!TradOrSimp.isSimplified(input) || disabledSC) {
          return "TC";
        } else {
          return "SC";
        }
      } else {
        return "EN";
      }
    }
    return _lang || "TC";
  };

  onMsgPress = (messageId) => {
    console.log("onMsgPress", messageId);
  };

  onMsgLongPress = (messageId) => {
    console.log("onMsgLongPress", messageId);
  };

  //#region MenuButton

  onMenuClick = () => {
    console.log("onMenuClick");
    let { showMenu } = this.state;
    this.setShowMenu(!showMenu);
  };

  setShowMenu = (f) => {
    this.setState(
      (state, props) => ({
        showMenu: f,
      }),
      () => {
        if (this.state.showMenu) {
          document.addEventListener(
            "mousedown",
            this.clickOutsideMenuBox,
            false
          );
        } else {
          document.removeEventListener(
            "mousedown",
            this.clickOutsideMenuBox,
            false
          );
        }
      }
    );
  };

  renderMenuBox = () => {
    let { lang, showMenu } = this.state;
    if (!showMenu) return;
    let buttons = [];
    if (lang === "TC") {
      buttons = [
        {
          type: "postback",
          image: "Buttons/001_c.png",
          title: "賬戶管理(開立／終止／轉名／搬屋及租賃)",
          payload: "CMD_BTN FAQ_Account",
        },
        {
          type: "postback",
          image: "Buttons/002_c.png",
          title: "賬單及報錶",
          payload: "CMD_BTN FAQ_Billing",
        },
        {
          type: "postback",
          image: "Buttons/003_c.png",
          title: "安裝工程",
          payload: "CMD_BTN FAQ_Installation",
        },
        {
          type: "postback",
          image: "Buttons/004_c.png",
          title: "維修保養",
          payload: "CMD_BTN FAQ_Maintenance",
        },
        {
          type: "postback",
          image: "Buttons/005_c.png",
          title: "其它",
          payload: "CMD_BTN FAQ_Other",
        },
      ];
    } else if (lang === "SC") {
      buttons = [
        {
          type: "postback",
          image: "Buttons/001_s.png",
          title: "账户管理(开立／终止／转名／搬屋及租赁)",
          payload: "CMD_BTN FAQ_Account",
        },
        {
          type: "postback",
          image: "Buttons/002_s.png",
          title: "账单及报錶",
          payload: "CMD_BTN FAQ_Billing",
        },
        {
          type: "postback",
          image: "Buttons/003_s.png",
          title: "安装工程",
          payload: "CMD_BTN FAQ_Installation",
        },
        {
          type: "postback",
          image: "Buttons/004_s.png",
          title: "维修保养",
          payload: "CMD_BTN FAQ_Maintenance",
        },
        {
          type: "postback",
          image: "Buttons/005_s.png",
          title: "其它",
          payload: "CMD_BTN FAQ_Other",
        },
      ];
    } else {
      buttons = [
        {
          type: "postback",
          image: "Buttons/001_e.png",
          title:
            "Account Management (Open/Terminate/Change Name/House Moving & Leasing)",
          payload: "CMD_BTN FAQ_Account",
        },
        {
          type: "postback",
          image: "Buttons/002_e.png",
          title: "Bill & Report meter reading",
          payload: "CMD_BTN FAQ_Billing",
        },
        {
          type: "postback",
          image: "Buttons/003_e.png",
          title: "Installation works",
          payload: "CMD_BTN FAQ_Installation",
        },
        {
          type: "postback",
          image: "Buttons/004_e.png",
          title: "Maintenance",
          payload: "CMD_BTN FAQ_Maintenance",
        },
        {
          type: "postback",
          image: "Buttons/005_e.png",
          title: "Others",
          payload: "CMD_BTN FAQ_Other",
        },
      ];
    }
    let browser = this.getBrowser();
    let buttonlists = _.map(buttons, (o, i) => {
      return (
        <div
          key={i}
          className={"t2e-menubox-btn " + browser}
          onClick={() => this.onMenuBtnClick(o)}
        >
          <img src={o.image} alt={o.title} />
        </div>
      );
    });

    return (
      <div
        className={"t2e-menubox " + browser}
        ref={(e) => (this.refMenuBox = e)}
      >
        {buttonlists}
      </div>
    );
  };

  onMenuBtnClick = (quickReply) => {
    let { remoteConfig } = this.state;
    if (!remoteConfig) return;

    let msg = {
      _id: v1(),
      createdAt: new Date(),
      user: {
        _id: 1,
        avatar: "Image/virtual-img.png",
      },
      msg: {
        text: quickReply.title,
      },
    };
    this.setState({
      showMenu: false,
    });
    this.MountChat.Append(msg);
    this.onQuickReply(quickReply);
  };

  clickOutsideMenuBox = (e) => {
    if (
      this.state.showMenu &&
      (this.refMenuBox.contains(e.target) || this.refMenuBtn.contains(e.target))
    ) {
      return;
    }
    this.setShowMenu(false);
  };

  renderMenuBtn = () => {
    let browser = this.getBrowser();
    return (
      <div
        className={"t2e-menuBtn " + browser}
        onClick={() => this.onMenuClick()}
        ref={(e) => (this.refMenuBtn = e)}
      >
        <img src="Icon/menu.png" alt="" draggable={false} />
      </div>
    );
  };

  //#endregion Menu Button End
  ToggleLang = (newLang) => {
    this.setState(
      {
        lang: newLang,
      },
      () => {
        this.SetPlaceHolder();
        this.welcomeMessage();
      }
    );
  };

  Leave = () => {
    let { remoteConfig } = this.state;
    if (!remoteConfig) return;
    this.openSurvey(0);
  };

  onDisclaimerConfirm = () => {
    this.setState(
      {
        showDisclaimer: false,
      },
      () => {
        this.setIdleTimeout();
      }
    );
  };

  //[LY]In the spec,  wonder why stoped
  onLeaveRedirect = async (timeout = 10000) => {
    let { lang } = this.state;
    setTimeout(() => {
      // window.open("https://www.towngas.com/" + lang + "/Home", "_self");
    }, timeout);
  };

  renderMaintenancePage = () => {
    const maintenanceHtml = this.state.maintenanceHtml;
    if (!maintenanceHtml) return null;
    return <div dangerouslySetInnerHTML={{ __html: maintenanceHtml }}></div>;
  };

  //#region Survey
  openSurvey = (step) => {
    this.setState(
      {
        showSurvey: true,
      },
      () => {
        this.MountSurvey.Start(step);
      }
    );
  };

  onSurveyClose = () => {
    this.setState({
      showSurvey: false,
    });
  };

  onSurveySetStep = (step) => {
    this.setState({
      surveyStep: step,
    });
  };

  //#endregion

  //[LY]SC related
  renderHeadLine() {
    let { lang } = this.state;
    let browser = this.getBrowser();
    let stage = process.env.REACT_APP_STAGE || "test";

    const toggleLanguage = () => {
      if (lang === "TC") {
        this.ToggleLang("EN");
      } else {
        this.ToggleLang("TC");
      }
    };

    return (
      <div className={"t2e-headline " + browser}>
        <div className={"t2e-headline-left " + browser}>
          <div className={"t2e-headline-icon logo " + browser}>
            <img src="Icon/tg_logo.png" alt="logo" draggable={false} />
          </div>
          <div className={"t2e-headline-text " + browser}>
            {Translate.virturalAssistant[lang]}
          </div>
          {/* {(stage === "test" || stage === "dev" || stage === "uat") && (
            <div className={"t2e-headline-debug " + browser}>{browser}</div>
          )} */}
        </div>
        <div className={"t2e-headline-right " + browser}>
          {!disabledSC ? (
            <>
              <div
                className={"t2e-headline-icon lang " + browser}
                onClick={() => this.ToggleLang("TC")}
              >
                <img
                  src={Translate.changeLangIcon["TC"]}
                  alt="lang"
                  draggable={false}
                />
              </div>
              <div
                className={"t2e-headline-icon lang " + browser}
                onClick={() => this.ToggleLang("SC")}
              >
                <img
                  src={Translate.changeLangIcon["SC"]}
                  alt="lang"
                  draggable={false}
                />
              </div>
              <div
                className={"t2e-headline-icon lang " + browser}
                onClick={() => this.ToggleLang("EN")}
              >
                <img
                  src={Translate.changeLangIcon["EN"]}
                  alt="lang"
                  draggable={false}
                />
              </div>
            </>
          ) : (
            <div
              className={"t2e-headline-icon lang " + browser}
              onClick={toggleLanguage}
            >
              <img
                src={Translate.changeLangIcon[lang === "TC" ? "EN" : "TC"]}
                alt="lang"
                draggable={false}
              />
            </div>
          )}
          <div
            className={"t2e-headline-icon close " + browser}
            onClick={() => this.Leave()}
          >
            <img src="Icon/close.png" alt="close" draggable={false} />
          </div>
        </div>
      </div>
    );
  }

  renderBullet() {
    let browser = this.getBrowser();
    return (
      <div className={"t2e-reminder-bullet " + browser}>
        <img src="Icon/bullet.png" alt="" draggable={false} />
      </div>
    );
  }

  //[LY]Seems not appears at prod. chatbot
  renderReminder() {
    let { showReminder, lang, reminderEnabled } = this.state;
    let browser = this.getBrowser();
    if (lang === "TC") {
      return (
        <div
          className={
            "t2e-reminder " +
            browser +
            (reminderEnabled && showReminder ? "" : " hide")
          }
        >
          <div className={"t2e-reminder-bubble " + browser}>
            <div className={"t2e-reminder-row " + browser}>
              <div className={"t2e-reminder-text " + browser}>
                查詢之前，請閱讀以下溫馨提示！
              </div>
            </div>
            <div className={"t2e-reminder-row " + browser}>
              {this.renderBullet()}
              <div>
                呢度未能解答閣下戶口嘅查詢及辦理服務(如開戶、約維修等)。
                <br />
                如有需要，請按此填上表格，我哋會盡快回覆！
              </div>
            </div>
            <div className={"t2e-reminder-row " + browser}>
              {this.renderBullet()}
              <div className={"t2e-reminder-text " + browser}>
                請以關鍵詞輸入查詢(15字內)，如：怎樣申請電子帳單?
              </div>
            </div>
            <div className={"t2e-reminder-row " + browser}>
              {this.renderBullet()}
              <div className={"t2e-reminder-text " + browser}>
                我係一個新手，有時候未能回答你嘅查詢，但我會不斷改善！
                <br />
                另外，你可以選擇下面嘅「主目錄」直接尋找答案！
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={
            "t2e-reminder " +
            browser +
            (reminderEnabled && showReminder ? "" : " hide")
          }
        ></div>
      );
    }
  }

  renderSendBtn = () => {
    return <img src="Icon/send.png" alt="" />;
  };

  renderChat = () => {
    let { lang, convID, remoteConfig, inputPlaceholder, available } =
      this.state;
    let browser = this.getBrowser();
    return (
      <div className={"t2e-chat " + browser}>
        <ZChat
          cssPrefix={"TownGas " + browser}
          onMounted={this.onMountChat}
          onSend={this.onSend}
          onQuickReply={this.onQuickReply}
          onAnswerReview={this.onAnswerReview}
          onMsgLongPress={this.onMsgLongPress}
          onMsgPress={this.onMsgPress}
          onInputRenderAddOns={this.renderMenuBox}
          user={{
            _id: 1,
            avatar: "Image/virtual-img.png",
          }}
          menuBtn={this.renderMenuBtn}
          sendBtn={this.renderSendBtn}
          inputPlaceHolder={inputPlaceholder}
          longAnswerLength={
            remoteConfig &&
            remoteConfig.MWSettings.WordLimit &&
            remoteConfig.MWSettings.WordLimit[lang]
          }
          //[LY]SC related
          readMoreCaption={
            lang === "TC"
              ? "閱讀更多..."
              : lang === "SC" && disabledSC == false
              ? "阅读更多..."
              : "Read more..."
          }
          readLessCaption={
            lang === "TC"
              ? "隱藏段落"
              : lang === "SC" && disabledSC == false
              ? "隐藏段落"
              : "Read less"
          }
          HTMLSpecialTagParser={TownGasParser.Parse /*this.HTMLTagParse*/}
          addOns={{
            convID: convID,
            lang: lang,
          }}
          available={available}
          lang={lang}
          browser={this.MountBrowser && this.MountBrowser.GetBrowser()}
        />
      </div>
    );
  };

  render() {
    let { showDisclaimer, lang, remoteConfig, showSurvey, convID, csrf } =
      this.state;
    let browser = this.getBrowser();
    if (this.state.maintenance == true) {
      return <div> {this.renderMaintenancePage()}</div>;
    } else {
      return (
        <div className={"Talk2Elain " + browser}>
          <ZDetectBrowser onMounted={this.onMountBrowser} />
          <Survey
            cssPrefix={"TownGas " + browser}
            show={showSurvey}
            convID={convID}
            eServiceURL={remoteConfig && remoteConfig.EService}
            onMounted={this.onMountSurvey}
            lang={lang}
            onClose={this.onSurveyClose}
            onSetStep={this.onSurveySetStep}
            onEnd={this.onLeaveRedirect}
            onRedirectToLiveAgent={this.RedirectToLiveAgent}
            onMeterReadingReturn={this.MeterReadingReturn}
            surveyText={remoteConfig && remoteConfig.Survey.Content}
            SurveyScore={remoteConfig && remoteConfig.Survey.SurveyScore}
            liveAgent={remoteConfig && remoteConfig.LiveAgent}
            addOns={{
              convID: convID,
              lang: lang,
              CSRF: csrf,
              triggereServiceLink: this.TriggereServiceLink,
              triggerLiveAgent: this.TriggerLiveAgent,
            }}
          />
          <div className={"Talk2Elain-inner " + browser}>
            {remoteConfig && showDisclaimer && (
              <Disclaimer
                cssPrefix={"TownGas " + browser}
                lang={lang}
                header={remoteConfig.Disclaimer.Header}
                content={remoteConfig.Disclaimer.Content}
                onConfirm={this.onDisclaimerConfirm}
                onCancel={() => this.onLeaveRedirect(0)}
                browser={browser}
              />
            )}
            {this.renderHeadLine()}
            {this.renderReminder()}
            {this.renderChat()}
          </div>
        </div>
      );
    }
  }
}

export default connect(_base.func.mapStateToProps, _base.actions)(Talk2Elain);
