/*REACT*/
import React, { Component } from 'react';
import PropsType from 'prop-types';

/*BASE*/
import LoadingSpin from 'react-loading-spin';

/*CSS*/
import './ZPopup.css';

class ZPopup extends Component {

  static propTypes = {
    cssPrefix: PropsType.string
  }

  static defaultProps = {
    cssPrefix: ''
  };

  constructor(props){
    super();
    this.state = {
      inuse: false
    };
  }

  componentDidMount(){
    this.setAllStates();
    if(this.props.onMounted){
      this.props.onMounted({
        Toggle: () => this._Toggle(),
        Close: () => this._Close(),
        Open: () => this._Open(),
        Alert: (msg, callback) => this._Alert(msg, callback),
        Loading: (callback) => this._Loading(callback),
        Ask: (msg, onConfirm, onCancel) => this._Ask(msg, onConfirm, onCancel),
        Pop: (onConfirm, onCancel) => this._Pop(onConfirm, onCancel)
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevStat){
    if(prevStat !== nextProps){
      return nextProps;
    }
  }

  componentWillUnmount(){
    document.removeEventListener('mousedown', this.clickOutside, false);
  }

  setAllStates = () => {
    this.setState((state, props) => ({
      ...props
    }));
  }

  onConfirm = () => {
    if(this.state.onConfirm){
      this.state.onConfirm();
    }else{
      this._Toggle();
    }
  }

  onCancel = () => {
    if(this.state.onCancel){
      this.state.onCancel();
    }else{
      this._Toggle();
    }
  }

  _Open = () => {
    if(this.state.inuse) return;
    this._Toggle();
  }

  _Close = () => {
    if(!this.state.inuse) return;
    this._Toggle();
  }

  _Toggle = () => {
    if(!this.state.inuse){
      document.addEventListener('mousedown', this.clickOutside, false);
    }else{
      document.removeEventListener('mousedown', this.clickOutside, false);
    }
    this.setState((state, props) => ({
      inuse: !state.inuse
    }));
  }

  _Pop = (onConfirm = null, onCancel = null) => {
    this.setState({
      mode: "default",
      message: "",
      onConfirm: onConfirm? onConfirm : () => {},
      onCancel: onCancel? onCancel : () => {}
    }, () => {
      this._Open();
    });
  }

  _Alert = (msg, callback) => {
    this.setState({
      mode: "alert",
      message: msg,
      onConfirm: () => {
        if(callback) callback();
        else{
          this._Close();
        }
      },
      onCancel: null
    }, () => {
      this._Open();
    });
  }

  _Loading = (callback) => {
    console.log("LOADING");
    this.setState({
      mode: "loading",
      message: "",
      onConfirm: () => {},
      onCancel: () => {}
    }, () => {
      this._Open();
      if(callback) callback();
    });
  }

  _Ask = (msg, onConfirm = null, onCancel = null) => {
    this.setState({
      mode: "ask",
      message: msg,
      onConfirm: onConfirm? onConfirm : () => {},
      onCancel: () => {
        if(onCancel) onCancel()
        this._Close();
      }
    }, () => {
      this._Open();
    });
  }

  clickOutside = (e) => {
    if (this.state.mode === "loading" || this.node.contains(e.target)) {
      return;
    }
    this._Toggle();
  }

  renderAlert = () => {
    let {cssPrefix, message} = this.state;
    return (
      <div className={cssPrefix + " zpopup-form"}>
        <div className={cssPrefix + " zpopup-box"}>
          <div className={cssPrefix + " zpopup-message"}>
            {message}
          </div>
          <div className={cssPrefix + " zpopup-box-btnrow"}>  
            {this.renderButtons("OK")}
          </div>
        </div>
      </div>
    );
  }

  renderConfirm = () => {
    let {cssPrefix, message} = this.state;
    return (
      <div className={cssPrefix + " zpopup-form"}>
        <div className={cssPrefix + " zpopup-box"}>
          <div className={cssPrefix + " zpopup-message"}>
            {message}
          </div>
          <div className={cssPrefix + " zpopup-btnrow"}> 
            {this.renderButtons("Cancel")} 
            {this.renderButtons("OK")}
          </div>
        </div>
      </div>
    );
  }

  renderLoading = () => {
    let {cssPrefix} = this.state;
    return(
      <div className={cssPrefix + " zpopup-loading-form"}>
        <LoadingSpin
            width = '3px'
            size = '30px'
            primaryColor = 'white'
            secondaryColor = 'transparent'
        />
      </div>
    );
  }

  renderDefault = () => {
    let {mode} = this.state;
    switch(mode){
      case "alert" : return this.renderAlert();
      case "ask" : return this.renderConfirm();
      case "loading" : return this.renderLoading();
      default: return this.props.children;
    }
  }

  renderButtons = (type) => {
    let {cssPrefix, submitting} = this.state;
    let buttons = {
      "OK": 
      <button type="button" className={cssPrefix + " zpopup-btn submit"} disabled={submitting} onClick={this.onConfirm} key={0}>
        <i className="fas fa-paper-plane"/> OK
      </button>,
      "Cancel":
      <button type="button" className={cssPrefix + " zpopup-btn cancel"} disabled={submitting} onClick={this.onCancel} key={1}>
        <i className="fas fa-ban"/> Cancel
      </button>
    }

    return buttons[type];
  }

  render() {
    let {cssPrefix, inuse} = this.state;
    return (
    <div>
      {
        inuse?
        <div className={cssPrefix + " ZPopup"}>
          <div className={cssPrefix + " ZPopup-bg"}></div>
          <div className={cssPrefix + " ZPopup-content"} ref={node => { this.node = node; }}>
            {this.renderDefault()}
          </div>
        </div>
        : ""
      }
    </div>
    );
  }
}

export default ZPopup;
