import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

//react
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

//redux
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import thunk from 'redux-thunk';

//page
import NoMatch from './components/_Base/NoMatch/NoMatch';
import Talk2Elain from './components/_Pages/Talk2Elain/Talk2Elain';

//css
import './index.css';
import './css/preset.css';

//others
import * as serviceWorker from './serviceWorker';
import * as _base from './_base';
import rootReducer from './_reducers';
import { v4 } from 'uuid';

if (process.env.NODE_ENV === 'production') {
  var console = {};
  console.log = function () { }
  window.console = console;
}

//localStorage.clear();

let locale = localStorage.getItem('locale');
locale = locale ? locale :
  localStorage.setItem('locale', _base.config.global.lang[0]);

let user = localStorage.getItem('User');
let deviceId = localStorage.getItem('deviceId');
if (!deviceId) {
  deviceId = deviceId ? deviceId : v4();
  localStorage.setItem('deviceId', deviceId);
}

const persistConfig = {
  key: 'root',
  whitelist: ['jwt', 'locale'],
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  {
    locale: locale ? locale : _base.config.global.lang[0],
    user: user ? JSON.parse(user) : '',
    deviceId: deviceId,
  },
  applyMiddleware(thunk)
)

let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Switch>
          <Route path="/:lang" exact component={Talk2Elain} />
          <Route path="/" exact component={Talk2Elain} />
          <Route component={NoMatch} />
        </Switch>
      </BrowserRouter>
    </PersistGate>
  </Provider>

  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
