import React, { Component } from "react";
import PropsType from "prop-types";

import htmlParser from "html-react-parser";
import TownGasParser from "./TownGasParser";
import Translate from "./Translate";

/*BASE*/
import ZPopup from "../../../_Utilities/ZPopup/ZPopup";

import _ from "lodash";
import moment from "moment";

import "./Survey.css";
import "./SurveyMS.css";

import { DOMAIN } from "../../../../_base/actions";
import axios from "axios";

class Survey extends Component {
  static propTypes = {
    cssPrefix: PropsType.string,
    convID: PropsType.string,
    onClose: PropsType.func,
    onSetStep: PropsType.func,
    onEnd: PropsType.func,
    onMounted: PropsType.func,
    onRedirectToLiveAgent: PropsType.func,
    onMeterReadingReturn: PropsType.func,
    surveyText: PropsType.object,
    SurveyScore: PropsType.string,
    eServiceURL: PropsType.object,
    liveAgent: PropsType.object,
    addOns: PropsType.object,
    show: PropsType.bool,
  };

  static defaultProps = {
    cssPrefix: "",
    convID: "",
    onClose: null,
    onSetStep: null,
    onEnd: null,
    onMounted: null,
    onRedirectToLiveAgent: null,
    onMeterReadingReturn: null,
    surveyText: {},
    SurveyScore: "",
    eServiceURL: {
      EN: "",
      TC: "",
      SC: "",
    },
    liveAgent: {
      Category: {
        EN: "",
        TC: "",
        SC: "",
      },
      NoService: {
        EN: "",
        TC: "",
        SC: "",
      },
      URL: {
        EN: "",
        TC: "",
        SC: "",
      },
    },
    addOns: {},
  };

  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount() {
    this._Reset();
    if (this.props.onMounted) {
      this.props.onMounted({
        Start: this._Start,
        Alert: this._Alert,
        TriggerLiveAgent: this._TriggerLiveAgent,
        TriggerMeterReport: this._TriggerMeterReport,
        Block: this._Block,
        Unblock: this._Unblock,
        Loading: this._Loading,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps,
      };
    }
  }

  onMountPopup = (callbacks) => {
    this.MountPopup = callbacks;
  };

  _Start = (step) => {
    this._Reset();
    this._SetStep(step);
  };

  _Alert = (msg) => {
    this.setState({
      alertmsg: msg,
      hideClose: true,
    });
    this._SetStep(999);
  };

  _Block = (time) => {
    this.setState(
      {
        blockCount: time / 1000,
        hideClose: true,
      },
      () => {
        this._SetStep(888);
        if (this.BlockCountDown) {
          clearInterval(this.BlockCountDown);
        }
        this.BlockCountDown = setInterval(this._CountDown, 1000);
      }
    );
  };

  _CountDown = () => {
    this.setState(
      (state, props) => ({
        blockCount: state.blockCount - 1,
      }),
      () => {
        if (this.state.blockCount <= 0) {
          this._Unblock();
        }
      }
    );
  };

  _Unblock = () => {
    if (this.BlockCountDown) {
      clearInterval(this.BlockCountDown);
    }
    this._Close();
  };

  _Close = () => {
    let { onClose } = this.state;
    this._SetStep(-1);
    this._Reset();
    if (onClose) onClose();

    // Only work when user enter the chatbot page through clicking the button in parent site
    window.close();
  };

  _SetStep = (step) => {
    let { onEnd, Selection } = this.state;
    let sel = Selection;
    switch (step) {
      case 0:
        sel = "undefined";
        break;
      case 2:
        sel = "Survey";
        break;
      case 3:
        sel = "eService";
        break;
      case 10:
        sel = "LiveAgent";
        break;
      case 11:
        sel = "LiveAgent(No Service)";
        break;
      default:
        break;
    }

    this.setState(
      {
        step: step,
        Selection: sel,
      },
      () => {
        if (step === 770 || step === 3) {
          //send value;
          this._Send();
          return;
        }

        if (step === 777) {
          //send value;
          if (onEnd) onEnd();
          return;
        }
      }
    );
  };

  _Send = async (nextStep = 777) => {
    let {
      Reason,
      Selection,
      showOther,
      showIncompleteOther,
      showIrrelevantOther,
      otherAns,
      incompleteOtherAns,
      irrelevantOtherAns,
      convID,
      Grade,
      addOns,
    } = this.state;

    console.log(addOns);

    let ReasonObj = {
      ...Reason,
    };

    if (!ReasonObj.incomplete) {
      ReasonObj.incomplete = [];
    }
    if (!ReasonObj.irrelevant) {
      ReasonObj.irrelevant = [];
    }
    if (!ReasonObj.complete) {
      ReasonObj.complete = [];
    }
    if (!ReasonObj.relevant) {
      ReasonObj.relevant = [];
    }

    if (Grade > 8) {
      if (ReasonObj.incomplete.length > 0) {
        ReasonObj.complete = ReasonObj.incomplete;
        ReasonObj.incomplete = [];
      }
      if (ReasonObj.irrelevant.length > 0) {
        ReasonObj.relevant = ReasonObj.irrelevant;
        ReasonObj.irrelevant = [];
      }
    }

    if (showIncompleteOther) {
      if (Grade < 5) {
        ReasonObj.incomplete.push(incompleteOtherAns.trim());
      } else if (Grade > 8) {
        ReasonObj.complete.push(incompleteOtherAns.trim());
      }
    }

    if (showIrrelevantOther) {
      if (Grade < 5) {
        ReasonObj.irrelevant.push(irrelevantOtherAns.trim());
      } else if (Grade > 8) {
        ReasonObj.relevant.push(irrelevantOtherAns.trim());
      }
    }

    ReasonObj.other = showOther ? otherAns.trim() : "";

    let payload = {
      context: {
        _id: convID,
        Grade: Grade,
        Reason: ReasonObj,
        Selection: Selection,
      },
      csrf: addOns.CSRF,
    };

    console.log("[-] Survey Sent", payload);
    //[LY]New api for the survey ranking
    let url = DOMAIN + "SurveyResult";

    this.MountPopup.Loading();
    try {
      await axios.post(url, payload);
      this.MountPopup.Close();
      this._SetStep(nextStep);
    } catch (e) {
      this.MountPopup.Close();
    }
  };

  _SetErrorMsg = (code = 0) => {
    let { lang } = this.state;
    let errorMsg = "";
    switch (code) {
      case 404:
        errorMsg = Translate.alertMsgReasonCheck[lang];
        break;
      case 403:
        errorMsg = Translate.alertMsgOthText[lang];
        break;
      default:
        errorMsg = "";
        break;
    }

    this.setState({
      errorMsg: errorMsg,
    });
  };

  _SetGrade = (grade) => {
    this.setState({
      Grade: grade + "",
    });
  };

  _SubmitSurvey = () => {
    console.log("_SubmitSurvey");

    let {
      Reason,
      showIncomplete,
      showIrrelevant,
      showOther,
      showIncompleteOther,
      showIrrelevantOther,
      otherAns,
      incompleteOtherAns,
      irrelevantOtherAns,
    } = this.state;

    if (!showIncomplete && !showIrrelevant && !showOther) {
      this._SetErrorMsg(404);
      return;
    }

    if (
      showIncomplete &&
      !showIncompleteOther &&
      (!Reason.incomplete || Reason.incomplete.length === 0)
    ) {
      this._SetErrorMsg(404);
      return;
    }

    if (
      showIrrelevant &&
      !showIrrelevantOther &&
      (!Reason.irrelevant || Reason.irrelevant.length === 0)
    ) {
      this._SetErrorMsg(404);
      return;
    }

    if (
      showIncomplete &&
      showIncompleteOther &&
      incompleteOtherAns.trim() === ""
    ) {
      this._SetErrorMsg(403);
      return;
    }

    if (
      showIrrelevant &&
      showIrrelevantOther &&
      irrelevantOtherAns.trim() === ""
    ) {
      this._SetErrorMsg(403);
      return;
    }

    if (showOther && otherAns.trim() === "") {
      this._SetErrorMsg(403);
      return;
    }

    this._SetErrorMsg();
    this._SetStep(770);
  };

  _RedirectSurvey = () => {
    let { Grade } = this.state;
    if (Grade >= 5 && Grade <= 8) {
      this._SetStep(770);
    } else {
      this._SetStep(2);
    }
  };

  _ToggleFlag = (which, value = null) => {
    console.log("[-] ToggleFlag", which, value);
    this.setState((state, props) => ({
      [which]: value ? value : !state[which],
      errorMsg: "",
    }));
  };

  _Reset = () => {
    this.setState({
      Grade: this.state.SurveyScore || "8",
      Reason: {
        incomplete: [],
        irrelevant: [],
        other: "",
      },
      Selection: "",
      showIncomplete: false,
      showIrrelevant: false,
      showOther: false,
      showIncompleteOther: false,
      showIrrelevantOther: false,

      otherAns: "",
      incompleteOtherAns: "",
      irrelevantOtherAns: "",

      alertmsg: "",
      blockCount: 0,
      hideClose: false,

      mRACError: false,
      mRReadingError: false,
      mRErrorMsg: "",
      mRACNo: "",
      mRReadings: 0,
    });
  };

  _ResetFlag = (name, FlagName) => {
    console.log("[-] ResetReason", name, FlagName);

    let FlagNameOther = FlagName + "Other";
    let FlagNameOtherAns = FlagName + "OtherAns";
    let { Reason } = this.state;

    Reason[name] = [];

    this.setState(
      {
        [FlagNameOther]: false,
        [FlagNameOtherAns]: "",
        Reason: Reason,
        errorMsg: "",
        hideClose: false,
      },
      () => {
        console.log(this.state.Reason);
      }
    );
  };

  _ToggleDetails = (name, which) => {
    console.log("[-] ToggleDetails", name, which);
    let { Reason } = this.state;
    if (!Reason[name]) return;

    let isSelected = Reason[name].includes(which);

    if (isSelected) {
      let idx = Reason[name].indexOf(which);
      Reason[name].splice(idx, 1);
    } else {
      Reason[name].push(which);
    }

    this.setState(
      (state, props) => ({
        Reason: Reason,
        errorMsg: "",
      }),
      () => {
        console.log(this.state.Reason);
      }
    );
  };

  _SetField = (name, value) => {
    console.log("[-] SetField", name, value);
    this.setState({
      [name]: value,
    });
  };

  LiveAgentAvailability = async () => {
    let url = DOMAIN + "LiveAgentAvailability";
    let res = await axios.get(url);

    let { service, success } = res.data;
    if (success && service) {
      return true;
    } else {
      return false;
    }
  };

  _TriggerLiveAgent = async (survey = false) => {
    let step = 0;
    if (await this.LiveAgentAvailability()) {
      step = 10;
    } else {
      step = 11;
    }
    this._SetStep(step);
    if (survey) this._Send(step);
  };

  _TriggerMeterReport = () => {
    this._SetStep(20);
  };

  _TriggereService = async () => {
    let { eServiceURL, lang, convID } = this.state;
    console.log(eServiceURL);
    console.log(eServiceURL.Message[lang].URL);
    window.open(
      eServiceURL.Message[lang].URL + convID,
      "MyWindow",
      "resizable=1,width=800,height=600,scrollbars=yes"
    );
    let payload = {
      context: "Enquiries",
      lang: lang,
      convID: convID,
    };

    let url = DOMAIN + "EService";
    let res = await axios.post(url, payload);
    console.log(res);

    this._SetStep(3);
  };

  _RedirectToLiveAgent = (category) => {
    let { onRedirectToLiveAgent } = this.state;
    if (onRedirectToLiveAgent) onRedirectToLiveAgent(category);
  };

  _SendMeterReport = async () => {
    let { convID, mRACNo, mRReadings, lang } = this.state;
    this.setState(
      {
        mRACError: !mRACNo,
        mRReadingError: !mRReadings,
        mRErrorMsg: false,
      },
      async () => {
        if (!mRACNo || !mRReadings) return;

        let payload = {
          convID: convID,
          mRACNo: mRACNo,
          mRReadings: mRReadings,
          date: Date(),
          lang: lang,
        };

        let url = DOMAIN + "meterReading/Report";
        this.MountPopup.Loading();
        try {
          let res = await axios.post(url, payload);
          this.MountPopup.Close();
          if (res.status !== "503") {
            //back to conversaction with ans if return success
            if (res.data && res.data.success) {
              let { onMeterReadingReturn } = this.state;
              if (onMeterReadingReturn)
                //call fn in Talk2Elain
                onMeterReadingReturn(res.data.message, res.data.ans_id);
              //Close pop up
              this._Close();
            } else {
              //show error message if return fail
              this.setState({
                mRErrorMsg: res.data.message,
              });
            }
          } else {
            //error handling
            this.setState({
              mRErrorMsg: Translate.connectFail[lang],
            });
          }
        } catch (e) {
          //error handling
          console.log(e);
          this.MountPopup.Close();
          this.setState({
            mRErrorMsg: Translate.connectFail[lang],
          });
        }
      }
    );
  };

  renderBlock() {
    let { addOns, lang, blockCount, cssPrefix } = this.state;

    return (
      <div className={cssPrefix + " t2e-survey-888"}>
        <div className={cssPrefix + " t2e-survey-title"}>
          <div className={cssPrefix + " t2e-survey-888-title"}>
            {TownGasParser.Parse(
              "",
              htmlParser(Translate.blockMsg[lang]),
              addOns
            )}
          </div>
        </div>
        <div className={cssPrefix + " t2e-survey-text"}>
          <div className={cssPrefix + " t2e-survey-888-text"}>
            {blockCount + "s"}
          </div>
        </div>
      </div>
    );
  }

  renderAlert() {
    let { alertmsg, addOns, lang, cssPrefix } = this.state;
    return (
      <div className={cssPrefix + " t2e-survey-999"}>
        <div className={cssPrefix + " t2e-survey-text"}>
          <div className={cssPrefix + " t2e-survey-999-text"}>
            {TownGasParser.Parse("", htmlParser(alertmsg), addOns)}
          </div>
        </div>
        <div
          className={cssPrefix + " t2e-survey-confirm"}
          onClick={() => this._Close()}
        >
          <div className={cssPrefix + " t2e-survey-confirm-text"}>
            {Translate.yesAns[lang]}
          </div>
        </div>
      </div>
    );
  }

  renderMeterReport() {
    let { lang, mRACError, mRReadingError, mRErrorMsg, addOns, cssPrefix } =
      this.state;
    return (
      <div className={cssPrefix + " t2e-survey-20"}>
        <div className={cssPrefix + " t2e-survey-20-title"}>
          {TownGasParser.Parse(
            "",
            htmlParser(Translate.meterReport.Title[lang]),
            addOns
          )}
        </div>
        <div className={cssPrefix + " t2e-survey-20-form"}>
          <div className={cssPrefix + " t2e-survey-20-form-title"}>
            {Translate.meterReport.AccountNo[lang]}
          </div>
        </div>
        {mRACError && (
          <div className={cssPrefix + " t2e-survey-20-form-error"}>
            {Translate.meterReport.ACInorrect[lang]}
          </div>
        )}
        <div className={cssPrefix + " t2e-survey-3-form-input-border"}>
          <div className={cssPrefix + " t2e-survey-20-form-input"}>
            <input
              type="text"
              name="mRACNo"
              id="mRACNo"
              onChange={(e) => this._SetField("mRACNo", e.target.value)}
            />
          </div>
        </div>

        <div className={cssPrefix + " t2e-survey-20-form"}>
          <div className={cssPrefix + " t2e-survey-20-form-title"}>
            {Translate.meterReport.Reading[lang]}
          </div>
        </div>
        {mRReadingError && (
          <div className={cssPrefix + " t2e-survey-20-form-error"}>
            {Translate.meterReport.ReadingIncorrect[lang]}
          </div>
        )}
        <div className={cssPrefix + " t2e-survey-20-form-error"}>
          {mRErrorMsg}
        </div>
        <div className={cssPrefix + " t2e-survey-3-form-input-border"}>
          <div className={cssPrefix + " t2e-survey-20-form-input"}>
            <input
              type="number"
              name="mRACNo"
              id="mRACNo"
              onChange={(e) => this._SetField("mRReadings", e.target.value)}
            />
          </div>
        </div>
        <div className={cssPrefix + " t2e-survey-20-date"}>
          {Translate.meterReport.ReportDate[lang] +
            moment().format(Translate.meterReport.DateFormat[lang])}
        </div>
        <div className={cssPrefix + " t2e-survey-btns"}>
          <div
            className={cssPrefix + " t2e-survey-reject"}
            onClick={() => this._Close()}
          >
            <div className={cssPrefix + " t2e-survey-reject-text"}>
              {Translate.cancel[lang]}
            </div>
          </div>
          <div
            className={cssPrefix + " t2e-survey-confirm"}
            onClick={() => this._SendMeterReport()}
          >
            <div className={cssPrefix + " t2e-survey-confirm-text"}>
              {Translate.submit[lang]}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLiveAgentBtns(cats) {
    let rtn = [];
    let { cssPrefix } = this.state;
    _.map(cats, (o, i) => {
      rtn.push(
        <div
          key={o}
          className={cssPrefix + " t2e-survey-confirm"}
          onClick={() => this._RedirectToLiveAgent(o)}
        >
          <div className={cssPrefix + " t2e-survey-confirm-text"}>{o}</div>
        </div>
      );
    });
    return rtn;
  }

  renderLiveAgent() {
    let { liveAgent, lang, addOns, cssPrefix } = this.state;
    let cats = liveAgent.Category[lang].split(";");
    return (
      <div className={cssPrefix + " t2e-survey-10"}>
        <div className={cssPrefix + " t2e-survey-10-title"}>
          {TownGasParser.Parse(
            "",
            htmlParser(Translate.liveAgent.Title[lang]),
            addOns
          )}
        </div>
        <div className={cssPrefix + " t2e-survey-10-subtitle"}>
          {Translate.liveAgent.SubTitle[lang]}
        </div>
        <div className={cssPrefix + " t2e-survey-10-btns"}>
          {this.renderLiveAgentBtns(cats)}
        </div>
      </div>
    );
  }

  renderLiveAgentFail() {
    let { lang, liveAgent, addOns, cssPrefix } = this.state;
    return (
      <div className={cssPrefix + " t2e-survey-text"}>
        <div className={cssPrefix + " t2e-survey-11-text"}>
          {TownGasParser.Parse(
            "",
            htmlParser(liveAgent.Message.NoService[lang]),
            addOns
          )}
        </div>
      </div>
    );
  }

  renderEndSurvey() {
    let { lang, addOns, cssPrefix } = this.state;
    return (
      <div className={cssPrefix + " t2e-survey-text"}>
        <div className={cssPrefix + " t2e-survey-777-text"}>
          {TownGasParser.Parse(
            "",
            htmlParser(Translate.surveyEnding[lang]),
            addOns
          )}
        </div>
      </div>
    );
  }

  renderCheckBox(FlagName, name, options) {
    let { Reason, lang, cssPrefix } = this.state;
    let FlagNameOther = FlagName + "Other";
    let FlagNameOtherAns = name + "OtherAns";
    let otherState = this.state[FlagNameOther];

    console.log(FlagNameOther, FlagNameOtherAns);

    let rtn = [];
    _.map(options, (o, i) => {
      rtn.push(
        <div
          key={name + "_" + o}
          className={cssPrefix + " t2e-survey-3-form-row-option"}
        >
          <div className={cssPrefix + " t2e-survey-3-form-row-option-left"}>
            <input
              type="checkbox"
              id={name + "_" + o}
              name={o}
              defaultChecked={Reason[name] && Reason[name].includes(o)}
              onClick={() => this._ToggleDetails(name, o)}
            />
          </div>
          <div className={cssPrefix + " t2e-survey-3-form-row-option-right"}>
            <label
              htmlFor={name + "_" + o}
              className={cssPrefix + " t2e-survey-3-form-row-option-title"}
            >
              {o}
            </label>
          </div>
        </div>
      );
    });

    let id = name + "others";
    let inputId = id + "ans";
    //other
    rtn.push(
      <div
        key={id}
        className={cssPrefix + " t2e-survey-3-form-row-option-others"}
      >
        <div
          className={cssPrefix + " t2e-survey-3-form-row-option-others-left"}
        >
          <input
            type="checkbox"
            id={id}
            name={id}
            defaultChecked={otherState}
            onClick={() => this._ToggleFlag(FlagNameOther)}
          />
        </div>
        <div
          className={cssPrefix + " t2e-survey-3-form-row-option-others-right"}
        >
          <label
            htmlFor={id}
            className={cssPrefix + " t2e-survey-3-form-row-option-title"}
          >
            {Translate.others[lang]}
          </label>
          {otherState && (
            <div
              className={
                cssPrefix + " t2e-survey-3-form-row-option-others-input"
              }
            >
              <input
                type="text"
                id={inputId}
                name={inputId}
                onChange={(e) =>
                  this._SetField(FlagNameOtherAns, e.target.value)
                }
              />
            </div>
          )}
        </div>
      </div>
    );

    return rtn;
  }

  renderOptions(showFlag, FlagName, name, label, ans) {
    let { lang, cssPrefix } = this.state;
    return (
      <div key={name} className={cssPrefix + " t2e-survey-3-form-row"}>
        <div className={cssPrefix + " t2e-survey-3-form-row-left"}>
          <input
            type="checkbox"
            id={name}
            name={name}
            defaultChecked={showFlag}
            onClick={(e) => {
              this._ToggleFlag(FlagName, e.target.checked);
              this._ResetFlag(name, FlagName);
            }}
          />
        </div>
        <div className={cssPrefix + " t2e-survey-3-form-row-right"}>
          <label
            htmlFor={name}
            className={cssPrefix + " t2e-survey-3-form-row-title"}
          >
            {label}
          </label>
          {showFlag && (
            <div className={cssPrefix + " t2e-survey-3-form-row-detail-title"}>
              {Translate.detailAnswer[lang]}
            </div>
          )}
          {showFlag && (
            <div className={cssPrefix + " t2e-survey-3-form-row-options"}>
              {this.renderCheckBox(FlagName, name, ans)}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderAskForInfo() {
    let {
      lang,
      surveyText,
      showIrrelevant,
      showIncomplete,
      showOther,
      errorMsg,
      cssPrefix,
      Grade,
    } = this.state;

    console.log("surveyText", surveyText);
    console.log("lang", lang);

    //TODO
    //incompleteAns and irrelevantAns should depends on the Grade to decide which set of surveyText to show
    let irrelevant;
    let incomplete;
    let incompleteAns;
    let irrelevantAns;
    let surveyTitle;
    if (Grade < 5) {
      surveyTitle = Translate.reasonTitle[lang];
      irrelevant = surveyText.Irrelevant[lang];
      incomplete = surveyText.Incomplete[lang];
      incompleteAns = surveyText.IncompleteAnswerSet[lang].split(";");
      console.log("incompleteAns", incompleteAns);
      irrelevantAns = surveyText.IrrelevantAnswerSet[lang].split(";");
      console.log("irrelevantAns", irrelevantAns);
    } else if (Grade > 8) {
      surveyTitle = Translate.highScoreReasonTitle[lang];
      irrelevant = surveyText.relevant[lang];
      incomplete = surveyText.complete[lang];
      incompleteAns = surveyText.completeAnswerSet[lang].split(";");
      console.log("incompleteAns", incompleteAns);
      irrelevantAns = surveyText.relevantAnswerSet[lang].split(";");
      console.log("irrelevantAns", irrelevantAns);
    }

    return (
      <div className={cssPrefix + " t2e-survey-3-form"}>
        <div className={cssPrefix + " t2e-survey-3-form-title"}>
          {surveyTitle}
        </div>
        <div className={cssPrefix + " t2e-survey-3-innerform"}>
          <div
            key="irrelevant"
            className={cssPrefix + " t2e-survey-3-form-rows"}
          >
            {this.renderOptions(
              showIrrelevant,
              "showIrrelevant",
              "irrelevant",
              irrelevant,
              irrelevantAns
            )}
          </div>
          <div
            key="incomplete"
            className={cssPrefix + " t2e-survey-3-form-rows"}
          >
            {this.renderOptions(
              showIncomplete,
              "showIncomplete",
              "incomplete",
              incomplete,
              incompleteAns
            )}
          </div>
          <div key="other" className={cssPrefix + " t2e-survey-3-form-rows"}>
            <div
              key="incomplete"
              className={cssPrefix + " t2e-survey-3-form-row"}
            >
              <div className={cssPrefix + " t2e-survey-3-form-row-left"}>
                <input
                  type="checkbox"
                  id={"OtherAns"}
                  name={"OtherAns"}
                  defaultChecked={showOther}
                  onClick={() => this._ToggleFlag("showOther")}
                />
              </div>
              <div className={cssPrefix + " t2e-survey-3-form-row-right"}>
                <label
                  htmlFor={"OtherAns"}
                  className={cssPrefix + " t2e-survey-3-form-row-title"}
                >
                  {Translate.others[lang]}
                </label>
                {showOther && (
                  <div
                    className={
                      cssPrefix +
                      " t2e-survey-3-form-row-others-textarea-border"
                    }
                  >
                    <div
                      className={
                        cssPrefix + " t2e-survey-3-form-row-others-textarea"
                      }
                    >
                      <textarea
                        id={"Other"}
                        name={"Other"}
                        onChange={(e) =>
                          this._SetField("otherAns", e.target.value)
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={cssPrefix + " t2e-survey-form-errormsg"}>
          {errorMsg}
        </div>
        <div className={cssPrefix + " t2e-survey-formbtns"}>
          <div
            className={cssPrefix + " t2e-survey-confirm"}
            onClick={() => this._SubmitSurvey()}
          >
            <div className={cssPrefix + " t2e-survey-confirm-text"}>
              {Translate.submit[lang]}
            </div>
          </div>
        </div>
        <div className={cssPrefix + " t2e-survey-formbtns"}>
          <div
            className={cssPrefix + " t2e-survey-confirm"}
            onClick={() => this._TriggerLiveAgent(true)}
          >
            <div className={cssPrefix + " t2e-survey-confirm-text"}>
              {Translate.triggerLiveAgent[lang]}
            </div>
          </div>
        </div>
        <div className={cssPrefix + " t2e-survey-formbtns"}>
          <div
            className={cssPrefix + " t2e-survey-confirm"}
            onClick={() => this._TriggereService()}
          >
            <div className={cssPrefix + " t2e-survey-confirm-text"}>
              {Translate.triggereService[lang]}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNumber(min, max, step = 1) {
    let rtn = [];
    let { cssPrefix } = this.state;
    for (let i = min; i <= max; i += step) {
      rtn.push(
        <div key={i} className={cssPrefix + " t2e-survey-1-num"}>
          {i}
        </div>
      );
    }
    return rtn;
  }

  renderAskForGrade() {
    let { lang, Grade, cssPrefix } = this.state;
    return (
      <div className={cssPrefix + " t2e-survey-1"}>
        <div className={cssPrefix + " t2e-survey-1-text"}>
          {Translate.starTitle[lang]}
        </div>
        <div className={cssPrefix + " t2e-survey-1-numbers"}>
          {this.renderNumber(1, 10)}
        </div>
        <div className={cssPrefix + " t2e-survey-range"}>
          <input
            className={cssPrefix + " t2e-survey-range-input"}
            type="range"
            min="1"
            max="10"
            value={Grade}
            onChange={(e) => this._SetGrade(e.target.value)}
          />
        </div>
        <div className={cssPrefix + " t2e-survey-btns"}>
          <div
            className={cssPrefix + " t2e-survey-confirm"}
            onClick={() => this._RedirectSurvey()}
          >
            <div className={cssPrefix + " t2e-survey-confirm-text"}>
              {Translate.submit[lang]}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAskForComment() {
    let { lang, cssPrefix } = this.state;
    return (
      <div className={cssPrefix + " t2e-survey-0"}>
        <div className={cssPrefix + " t2e-survey-0-text"}>
          {Translate.surveyTitle[lang]}
        </div>
        <div className={cssPrefix + " t2e-survey-btns"}>
          <div
            className={cssPrefix + " t2e-survey-reject"}
            onClick={() => this._Close()}
          >
            <div className={cssPrefix + " t2e-survey-reject-text"}>
              {Translate.noAns[lang]}
            </div>
          </div>
          <div
            className={cssPrefix + " t2e-survey-confirm"}
            onClick={() => this._SetStep(1)}
          >
            <div className={cssPrefix + " t2e-survey-confirm-text"}>
              {Translate.yesAns[lang]}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let { step, show, hideClose, cssPrefix } = this.state;
    if (!show) return null;
    return (
      <div className={cssPrefix + " t2e-survey"}>
        <ZPopup onMounted={this.onMountPopup} cssPrefix={cssPrefix} />
        <div className={cssPrefix + " t2e-survey-inner"}>
          {!hideClose && (
            <div
              className={cssPrefix + " t2e-survey-3-close"}
              onClick={() => this._Close()}
            >
              <i className={cssPrefix + " fas fa-times"} />
            </div>
          )}
          {step === 0 && this.renderAskForComment()}
          {step === 1 && this.renderAskForGrade()}
          {step === 2 && this.renderAskForInfo()}
          {step === 777 && this.renderEndSurvey()}
          {step === 10 && this.renderLiveAgent()}
          {step === 11 && this.renderLiveAgentFail()}
          {step === 999 && this.renderAlert()}
          {step === 888 && this.renderBlock()}
          {step === 20 && this.renderMeterReport()}
        </div>
      </div>
    );
  }
}

export default Survey;
