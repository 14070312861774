import React, { Component } from "react";
import PropsType from "prop-types";

import ZCTouch from "../ZCTouch";

import "./ZCMsg.css";

class ZCMAnswerReview extends Component {
  static propTypes = {
    answer_id: PropsType.string,
    Message: PropsType.string,
    yesButton: PropsType.string,
    noButton: PropsType.string,
    lang: PropsType.string,
  };

  static defaultProps = {
    answer_id: "",
    buttons: "",
    yesButton: {
      TC: "是",
      SC: "是",
      EN: "Yes",
    },
    noButton: {
      TC: "否",
      SC: "否",
      EN: "No",
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      like: false,
    };
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps,
      };
    }
  }

  _onAnswerReview = async (_like) => {
    let { answer_id, onAnswerReview } = this.state;
    await onAnswerReview(answer_id, _like);
    this.setState({
      disabled: true,
      like: _like,
    });
  };

  render() {
    let { cssPrefix, Message, yesButton, noButton, disabled, like, lang } =
      this.state;
    //console.log("Message", Message);
    return (
      <div className={cssPrefix + " zchat-msg-answer-review"}>
        <div className={cssPrefix + " zchat-msg-answer-review-Message"}>
          {/* [LY]Hardcoded */}
          {Message[lang]}
        </div>
        <div className={cssPrefix + " zchat-msg-answer-review-action-wrapper"}>
          <ZCTouch
            cssPrefix={cssPrefix}
            onPress={() => this._onAnswerReview(true)}
            disabled={disabled}
          >
            <div
              className={`${cssPrefix} zchat-msg-answer-review-btn ${
                disabled ? `disabled ${like ? "active" : ""}` : ""
              }`}
            >
              <div className={`${cssPrefix} zchat-msg-answer-review-btn-text`}>
                {yesButton[lang]}
              </div>
            </div>
          </ZCTouch>
          <ZCTouch
            cssPrefix={cssPrefix}
            onPress={() => this._onAnswerReview(false)}
            disabled={disabled}
          >
            <div
              className={`${cssPrefix} zchat-msg-answer-review-btn ${
                disabled ? `disabled ${like ? "" : "active"}` : ""
              }`}
            >
              <div className={`${cssPrefix} zchat-msg-answer-review-btn-text`}>
                {noButton[lang]}
              </div>
            </div>
          </ZCTouch>
        </div>
      </div>
    );
  }
}

export default ZCMAnswerReview;
