/*REACT*/
import React, { Component } from "react";
import PropsType from "prop-types";

class ZDetectBrowser extends Component {
  static propTypes = {
    onMounted: PropsType.func,
  };

  static defaultProps = {
    onMounted: null,
  };

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      () => {
        this.DetectBrowser();
      }
    );

    if (this.props.onMounted) {
      this.props.onMounted({
        isAndroid: () => {
          return this.GetState("isAndroid");
        },
        isGoogleBot: () => {
          return this.GetState("isGoogleBot");
        },
        isIE: () => {
          return this.GetState("isIE");
        },
        isIE11: () => {
          return this.GetState("isIE11");
        },
        isEdge: () => {
          return this.GetState("isEdge");
        },
        isFirefox: () => {
          return this.GetState("isFirefox");
        },
        isOpera: () => {
          return this.GetState("isOpera");
        },
        isChrome: () => {
          return this.GetState("isChrome");
        },
        isSafari: () => {
          return this.GetState("isSafari");
        },
        isBlink: () => {
          return this.GetState("isBlink");
        },
        GetBrowser: () => {
          return this.GetBrowser();
        },
      });
    }
  }

  GetBrowser = () => {
    return this.state.browser;
  };

  GetState = (stateName) => {
    return this.state[stateName];
  };

  DetectBrowser = () => {
    let browser = "";
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf("android") !== -1;
    const isGoogleBot = ua.indexOf("googlebot") !== -1;
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    const isFirefox =
      typeof InstallTrigger !== "undefined" ||
      ua.indexOf("firefox") !== -1 ||
      ua.indexOf("fennec") !== -1 ||
      ua.indexOf("fxios") !== -1;
    const isOpera =
      (!!window.opr && !!window.opr.addons) ||
      !!window.opera ||
      ua.indexOf(" OPR/") >= 0;
    const isChrome =
      !isGoogleBot &&
      !isOpera &&
      !!window.chrome &&
      (!!window.chrome.webstore ||
        navigator.vendor.toLowerCase().indexOf("google inc.") !== -1);
    const isEdge = !isIE && !isChrome && ua.indexOf("edg") !== -1;
    const isSafari = !isChrome && ua.indexOf("safari") !== -1;
    const isIOS = /ipad|iphone|ipod/.test(ua);
    const isIOSChrome = isIOS && ua.indexOf("crios") !== -1;
    const isBlink = (isChrome || isOpera) && !!window.CSS;

    // Adding iOS Chrome detection
    if (isIOSChrome) {
      browser = "ios-chrome";
    } else if (isIE11) {
      browser = "ie11";
    } else if (isIE) {
      browser = "ie";
    } else if (isEdge) {
      browser = "edge";
    } else if (isFirefox) {
      browser = "firefox";
    } else if (isOpera) {
      browser = "opera";
    } else if (isChrome) {
      browser = "chrome";
    } else if (isSafari) {
      browser = "safari";
    } else if (isBlink) {
      browser = "blink";
    } else if (isGoogleBot) {
      browser = "googlebot";
    } else {
      browser = "unknown";
    }

    browser = (isAndroid ? "android " : "") + browser;
    browser = (isGoogleBot ? "googlebot " : "") + browser;

    console.log("[-] Browser Detected: " + browser);
    this.setState({
      browser,
      isAndroid,
      isGoogleBot,
      isIE11,
      isIE,
      isEdge,
      isFirefox,
      isOpera,
      isChrome,
      isSafari,
      isBlink,
      isIOSChrome,
    });

    return browser;
  };

  render() {
    return <div />;
  }
}

export default ZDetectBrowser;
