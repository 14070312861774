import React from "react";

import ZCMImage from "../../../_Utilities/ZChat/ZCMsg/ZCMImage";
import ZCMVideo from "../../../_Utilities/ZChat/ZCMsg/ZCMVideo";

import _ from "lodash";

import "./TownGasParser.css";
import "./TownGasParserMS.css";

class TownGasParser {
  static Parse(cssPrefix, parsed, iaddOns, onSend) {
    if (typeof parsed === "string") return parsed;

    if (!Array.isArray(parsed)) {
      parsed = [parsed];
    }

    let rendered = [];
    _.map(parsed, (o, i) => {
      let {
        answer,
        className,
        children,
        height,
        href,
        intent,
        option,
        poster,
        target,
        title,
        src,
        width,
      } = o.props || {};

      switch (o.type) {
        case "p":
          if (children && Array.isArray(children)) {
            rendered.push(<p>{TownGasParser.Parse(cssPrefix, children, iaddOns) || children}</p>);
          } else {
            rendered.push(o);
          }
          break;
        case "tr":
        case "td":
          rendered.push(
            <div key={o.key} className={cssPrefix + " zc-" + o.type}>
              {(Array.isArray(children) &&
                TownGasParser.Parse(cssPrefix, children, iaddOns)) ||
                children}
            </div>
          );
          break;

        case "url":
          if (className) {
            rendered.push(
              <div
                key={o.key}
                className={cssPrefix + " zchat-msg-htmltag-url"}
                onClick={() =>
                  iaddOns.triggereServiceLink(
                    className,
                    iaddOns.lang,
                    iaddOns.answerID,
                    iaddOns.convID,
                    true
                  )
                }
              >
                {o.props.children}
              </div>
            );
          } else {
            rendered.push(
              <div
                key={o.key}
                className={cssPrefix + " zchat-msg-htmltag-url"}
                onClick={() => {
                  if (!cssPrefix.includes("t2e-disclaimer-content-detail")) {
                    window.open(
                      href,
                      target || "MyWindow",
                      option ||
                        "resizable=1, width=800, height=600, scrollbars=yes"
                    );
                  }
                }}
              >
                {children.props && children.props.children}
              </div>
            );
          }
          break;

        case "mail":
          rendered.push(
            <a
              key={o.key}
              className={cssPrefix + " zchat-msg-htmltag-mail"}
              href={"mailto:" + href}
            >
              {children.props && children.props.children}
            </a>
          );
          break;

        case "iframe":
          let x = {
            ...o,
            props: {
              ...o.props,
              width: "100%",
              height: "350px",
              allow: "fullscreen"
            },
          };
          rendered.push(x);
          break;

        case "img":
          break;
        case "imagelink":
          rendered.push(
            <ZCMImage
              key={o.key}
              cssPrefix={cssPrefix}
              src={children}
              title={title}
              onImageClick={() => {}}
            ></ZCMImage>
          );
          break;

        case "imageurl":
          rendered.push(
            <ZCMImage
              key={o.key}
              cssPrefix={cssPrefix}
              onImageClick={() => {
                if (href) {
                  const lastSlashIndex = href.lastIndexOf("/");
                  const catagory =
                    lastSlashIndex !== -1
                      ? href.substring(lastSlashIndex + 1)
                      : "";

                  window.open(
                    href,
                    target || "MyWindow",
                    option ||
                      "resizable=1, width=800, height=600, scrollbars=yes"
                  );
                  iaddOns.triggereServiceLink(
                    catagory,
                    iaddOns.lang,
                    iaddOns.answerID,
                    iaddOns.convID,
                    false
                  );
                }
                onSend({
                  image_url_answer_input: answer,
                  image_url_intent_input: intent,
                });
              }}
              src={src}
              title={title}
              width={width}
              height={height}
            ></ZCMImage>
          );
          break;

        case "videourl":
          rendered.push(
            <div key={o.key} className={cssPrefix + " zchat-msg-htmltag-video"}>
              <ZCMVideo
                key={o.key}
                cssPrefix={cssPrefix}
                video={{
                  src: src,
                  poster: poster,
                }}
              ></ZCMVideo>
            </div>
          );
          break;

        case "liveagent":
          rendered.push(
            <div
              key={o.key}
              className={cssPrefix + " zchat-msg-htmltag-url"}
              onClick={() => iaddOns.triggerLiveAgent()}
            >
              {children}
            </div>
          );
          break;

        case "meterreading":
          rendered.push(
            <div
              key={o.key}
              className={cssPrefix + " zchat-msg-htmltag-url"}
              onClick={() => iaddOns.triggerMeterReport()}
            >
              {children}
            </div>
          );
          break;

        case "imagerow":
          rendered.push(
            <div style={{ display: "flex", flexWrap: "wrap" }} key={o.key}>
              {TownGasParser.Parse(
                cssPrefix,
                o.props.children,
                iaddOns,
                onSend
              )}
            </div>
          );
          break;

        case "details":
          rendered.push(
            <details
              key={o.key}
              style={{
                border: "2px solid black",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              {TownGasParser.Parse(cssPrefix, children, iaddOns)}
            </details>
          );
          break;

        default:
          if (children && children.props) {
            rendered.push(TownGasParser.Parse(cssPrefix, children, iaddOns));
          } else {
            rendered.push(o);
          }
      }
    });
    return rendered;
  }
}

export default TownGasParser;
